import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  InputAdornment,
  OutlinedInput,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  MoreVert,
  PictureAsPdf,
  Description,
  Person,
  Close,
  CloudUpload,
} from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CssBaseline from "@mui/material/CssBaseline";
import getCheckoutTheme from "./getCheckoutTheme";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PurplegradientIcon from "./Purplegradient/PurplegradientIcon";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import AppBar from "./AppBar3";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
// import PateintDocument from "./PatientDocument";
// import Auth from "./Auth";
import Cookies from "js-cookie";
import { useSnackbar } from './Snackbar';
import LoadingSpinner from './LoadingSpinner';
import dayjs from 'dayjs'; 

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const GreyBackgroundBox = styled(Box)({
  backgroundColor: "#edeff3",
  minHeight: "100vh",
  minWidth: '100vw',
});

const PateintProfile = () => {
  //   Auth();
  const checkoutTheme = createTheme(getCheckoutTheme("light"));
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, patient_id } = location.state || {};
  const { showSnackbar } = useSnackbar();

  const [userProfileData, setUserProfileData] = useState("");
  const [id ,setId] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] =useState("");
  const [referredBy, setReferredBy] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const[consentToText, setConsentToText] = useState(false);
  const [emergencyContact, setEmergencyContact] = useState("");
  const [phone, setPhone] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [gender, setGender] = useState('');
  const [allowPatientToLogin, setAllowPatientToLogin] = useState(false);
  const [aadhaarNumber, setAadhaarNumber] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [doctorAssigned, setDoctorAssigned] = useState('');
  const [showFullAadhaar, setShowFullAadhaar] = useState(false);
  const [showFullPan, setShowFullPan] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [timeoutIdPan, setTimeoutIdPan] = useState(null);
  const [loading, setLoading] = useState(false);


  const [isAadhaarValid, setIsAadhaarValid] = useState(true);
  const [isPanEdited, setIsPanEdited] = useState(true);

  // List of states in India for the dropdown
  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  const [emailError, setEmailError] = useState(false);
  const [isAddressEdited, setIsAddressEdited] = useState(false);

  const [emailBorderColor, setEmailBorderColor] = useState("");

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (emailRegex.test(email)) {
      setEmailError(false);
      // setEmailBorderColor(""); // Reset border color if email is valid
      return true;
    } else {
      setEmailError(true);
      // setEmailBorderColor("red"); // Set border color to red if email is invalid
      return false;
    }
  };

  const handleItemClick = (item) => {
    navigate('/home')
  };

  // const handleToggleVisibility = () => {
  //   setShowFullAadhaar((prevShowFullAadhaar) => !prevShowFullAadhaar);
  // };

  const handleToggleVisibility = () => {
    setShowFullAadhaar((prevShowFullAadhaar) => {
      // Toggle showFullPan state
      const newShowFullAadhaar = !prevShowFullAadhaar;
  
      // Clear previous timeout if exists
      if (timeoutId) clearTimeout(timeoutId);
  
      // Set new timeout to hide after 10 seconds if unhidden
      if (newShowFullAadhaar) setTimeoutId(setTimeout(() => setShowFullAadhaar(false), 10000));
  
      return newShowFullAadhaar;
    });
  };
  

  const handleToggleVisibilityForPan = () => {
    setShowFullPan((prevShowFullPan) => {
      // Toggle showFullPan state
      const newShowFullPan = !prevShowFullPan;
  
      // Clear previous timeout if exists
      if (timeoutIdPan) clearTimeout(timeoutIdPan);
  
      // Set new timeout to hide after 10 seconds if unhidden
      if (newShowFullPan) setTimeoutIdPan(setTimeout(() => setShowFullPan(false), 10000));
  
      return newShowFullPan;
    });
  };
  



  const getFieldProps = (fieldName) => {
    const isEmailField = fieldName === "email";
    const value = eval(fieldName);

    return {
      name: fieldName,
      onChange: handleChange,
      value: value,
      error: isAddressEdited && value === "",
      helperText:
        isAddressEdited && value === "" ? "This field is required" : "",
      style: {
        borderColor:
          isAddressEdited && value === ""
            ? "red"
            : isEmailField && emailBorderColor,
      },
    };
  };

  const handleChangeState = (event) => {
    setState(event.target.value);
    setIsAddressEdited(true);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  useEffect(() => {
    const anyFieldNotEmpty = !( aadhaarNumber.length === 0 || aadhaarNumber.length === 14 );
    setIsAadhaarValid(anyFieldNotEmpty);
  }, [aadhaarNumber]);

  useEffect(() => {
    const anyFieldNotEmpty = !( panNumber.length === 0 || panNumber.length === 10 );
    setIsPanEdited(anyFieldNotEmpty);
  }, [panNumber]);


  // const handleItemClick = () => {
  //   // Check if location.state and location.state.userId are defined
  //   if (location.state && location.state.userId) {
  //     console.log('userId==>>', location.state.userId);
  //     navigate("/patient-profile", {
  //       state: {
  //         userId: location.state.userId
  //       },
  //     });
  //   } else {
  //     console.error('userId is undefined or null in location.state');
  //     // Handle the case where userId is not defined in location.state
  //   }
  // };

  const token = Cookies.get("token");
  if (!token) {
    navigate("/");
  }

//   const getUser = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_ENVIRONMENT}/api/users/user-profile`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setUserProfileData(response.data.user);
//       setEmail(response.data.user.email);
//       setEmergencyContact(response.data.user.emergency_contact || "");
//       setAddress1(
//         response.data.user.address ? response.data.user.address.address1 : ""
//       );
//       setAddress2(
//         response.data.user.address ? response.data.user.address.address2 : ""
//       );
//       setCity(
//         response.data.user.address ? response.data.user.address.city : ""
//       );
//       setState(
//         response.data.user.address ? response.data.user.address.state : ""
//       );
//       setZip(response.data.user.address ? response.data.user.address.zip : "");
//     } catch (error) {
//       console.log("error", error);
//     }
//   };

  const getPateintDetailsById = async (userId) => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_ENVIRONMENT}/api/users/user-details`, {
        params: {
          _id: patient_id
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

     setEmail(response.data.user.email ? response.data.user.email : '');
     setFirstName(response.data.user.first_name ? response.data.user.first_name : '');
     setLastName(response.data.user.last_name ? response.data.user.last_name : '');
     setDateOfBirth(response.data.user.dob ? response.data.user.dob : '')
     setReferredBy(response.data.user.referred_by ? response.data.user.referred_by : '');
     setHeight(response.data.user.height ? response.data.user.height : '');
     setWeight(response.data.user.weight ? response.data.user.weight : '');
     setBloodGroup(response.data.user.blood_group ? response.data.user.blood_group : '');
     setEmergencyContact(response.data.user.emergency_contact ? response.data.user.emergency_contact : '');
     setPhone(response.data.user.phone ? response.data.user.phone : '');
     setAddress1(response.data.user.address ? response.data.user.address.address1 : '');
     setAddress2(response.data.user.address ? response.data.user.address.address2 : '');
     setCity(response.data.user.address ? response.data.user.address.city : '');
     setState(response.data.user.address ? response.data.user.address.state : '');
     setZip(response.data.user.address ? response.data.user.address.zip : '')
     setId(response.data.user._id ? response.data.user._id : '');
     setConsentToText(response.data.user.has_consent_enabled ? response.data.user.has_consent_enabled : false);
     setGender(response.data.user.gender ? response.data.user.gender : '');
    setAllowPatientToLogin(response.data.user ? response.data.user.allow_login : true);
    setAadhaarNumber(response.data.user.aadhaar_number ? response.data.user.aadhaar_number : '');
    setPanNumber(response.data.user.pan_number ? response.data.user.pan_number : '');
    setDoctorAssigned(response.data.user.assigned_doctor ? response.data.user.assigned_doctor : '');

    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false)
  };

  useEffect(() => {
    if(userId){
      getPateintDetailsById(userId);
    }
  }, [userId]);

  const [data, setData] = useState(false);

  const isFormValid = () => {
    if (isAddressEdited) {
      return (
        // email !== "" &&
        firstName !== "" &&
        lastName !== "" &&
        dateOfBirth !== "" &&
        // referredBy !== "" &&
        // height !== "" &&
        // weight !== "" &&
        // bloodGroup !== "" &&
        // emergencyContact !== "" &&
        phone !== "" &&
        address1 !== "" &&
        city !== "" &&
        state !== "" &&
        zip !== ""
      );
    } else {
      return (
        // email !== "" &&
        firstName !== "" &&
        lastName !== "" &&
        dateOfBirth !== "" &&
        // referredBy !== "" &&
        // height !== "" &&
        // weight !== "" &&
        // bloodGroup !== "" &&
        // emergencyContact !== "" &&
        phone !== ""
      );
    }
  };

  const handleAadhaarNumberChange = (event) => {
    const { value, name } = event.target;
    const cleanedValue = value.replace(/\D/g, '');
    let formattedAadhaar = '';
    for (let i = 0; i < cleanedValue.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedAadhaar += '-';
      }
      formattedAadhaar += cleanedValue.charAt(i);
    }
    setAadhaarNumber(formattedAadhaar.slice(0, 14))
  };
  
  

  const handlePanCardChange = (event) => {
    const { value, name } = event.target;
    const upperCaseValue = value.toUpperCase();

    const firstFiveChars = upperCaseValue.slice(0, 5).replace(/[^A-Z]/g, '');
    const nextFourDigits = upperCaseValue.slice(5, 9).replace(/\D/g, '');
    const lastChar = upperCaseValue.slice(9, 10).replace(/[^A-Z]/g, '');
    
    const panCard = `${firstFiveChars}${nextFourDigits}${lastChar}`;
    setPanNumber(panCard);
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    switch (name) {
      case "email":
        setEmail(value);
        validateEmail(value);
        break;
      case "firstName":
        setFirstName(value);
        break; 
      case "lastName":
        setLastName(value);
        break;
      case "dateOfBirth":
        setDateOfBirth(value);
        break;
      case "referredBy":
        setReferredBy(value);
        break;  
        case 'height':
          // Allow only numbers, ' and " characters
          const heightValue = value.replace(/[^0-9'" ]/g, '');
          setHeight(heightValue);
          break;
        case 'weight':
          // Allow only numbers and . characters
          const weightValue = value.replace(/[^0-9.]/g, '');
          setWeight(weightValue);
          break; 
      case "bloodGroup":
        setBloodGroup(value);
        break; 
      case "emergencyContact":
        setEmergencyContact(value);
        break;
      case "address1":
        setAddress1(value);
        break;
      case "address2":
        setAddress2(value);
        break;
      case "city":
        setCity(value);
        break;
      case "state":
        setCity(value);
        break;
      case "zipcode":
        setZip(value);
        break;
      case "consentToText":
        setConsentToText(checked);
        break;
      case "doctorAssigned":
        setDoctorAssigned(value);
        break;
      case "allowPatientToLogin":
        setAllowPatientToLogin(value === 'yes' ? true : false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const anyFieldNotEmpty = address1 !== '' || city !== '' || state !== '' || zip !== '';
    setIsAddressEdited(anyFieldNotEmpty);
  }, [address1,city,state,zip]);

  const handleNumericInputChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 10);
    setEmergencyContact(numericValue);
  };
  const handleNumericInputChangeForPhone = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 10);
    setPhone(numericValue);
  };

  const handleZipInputChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 6);
    setZip(numericValue);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    const profileData = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      dob: dateOfBirth,
      emergency_contact: emergencyContact,
      phone: phone,
      has_consent_enabled: consentToText,
      address: {
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        zip: zip,
      },
      height: height,
      weight: weight,
      blood_group: bloodGroup,
      referred_by: referredBy,
      gender: gender,
      allow_login: allowPatientToLogin,
      aadhaar_number: aadhaarNumber,
      pan_number: panNumber,
      assigned_doctor: doctorAssigned,
    };

  
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENVIRONMENT}/api/users/${userId}`, // userId is actually patientId
        profileData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      // getUser();
      // navigate("/home");
      showSnackbar(`Patient profile updated.`, 'success');
      getPateintDetailsById(userId);
      navigate("/patient-profile", {
        state: {
          userId: userId,
          patient_id: patient_id
        },
      });
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false);
  };
  
  const handleCardClick = () => {
    navigate("/patient-profile", {
      state: {
        userId: id,
        patient_id:patient_id
      },
    });
  };

  const today = new Date().toISOString().split('T')[0];

  const handleChangeDate = (event) => {
    const date = dayjs(event.target.value);
    const today = dayjs();
    if (date && date.isBefore(today, 'day')) {
      setDateOfBirth(event.target.value);
    } else if (!event.target.value) {
      setDateOfBirth(''); // Handle clearing the date input
    } else {
      setDateOfBirth(today.format('YYYY-MM-DD')); // Set to today if a future date is selected
    }
  };
  
  

  return (
    <ThemeProvider theme={checkoutTheme}>
      <GreyBackgroundBox>
      <Box sx={{ display: "flex" }}>
      <CssBaseline />
        <AppBar mainPage="Patient Details" currentPage="Edit Profile" userId={patient_id}/>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? "#EDEFF3"
                : theme.palette.grey[900],
            flexGrow: 0,
            height: "100vh",
            overflow: "auto",
            width: "100vw",
            // mt: 5,
          }}
        >
          <Box sx={{ mt: 2, mb: 0, display: 'flex', alignItems: 'center', ml: 3 }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{ color: '#7B26C9', ml: -1, mr: -1 }} />} aria-label="breadcrumb">
          <Typography
            variant="div"
            sx={{
              color: '#7B26C9',
              textDecoration: 'none',
              cursor: 'pointer',
              borderBottom: '2px solid #7B26C9',
              display: 'inline-block',
              '&:hover': {
                textDecoration: 'none',
              },
            }}
            onClick={handleCardClick}
          >
            Patient Details
          </Typography>
          <Typography color="text.primary" sx={{ color: '#7B26C9' }}>Edit Profile</Typography>
        </Breadcrumbs>
      </Box>
        <Container sx={{ backgroundColor: "#ffffff", pb: 1,mt: 8 }}>
          <Box sx={{ mt: 4 }}>
            <Container
              maxWidth="md"
              sx={{ bgcolor: "#ffffff", p: 4, mt: 4, mb: 4, borderRadius: 2 }}
            >
              <Box component="form" noValidate autoComplete="off">
                <hr
                  style={{
                    borderTop: "1px solid #CCCCCC",
                    width: "100%",
                    margin: "8px 0",
                  }}
                />
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4} sx={{ mb: "3%" }}>
                    <Typography variant="buttonText2" gutterBottom>
                      Login Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="phoneNumber"
                        sx={{ textAlign: "left" }}
                        required
                      >
                        <Typography variant="label">Phone Number</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone Number"
                        onChange={handleNumericInputChangeForPhone}
                        value={phone}
                        required
                        inputProps={{
                          inputMode: "numeric", // Ensure numeric keyboard on mobile
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel htmlFor="email" sx={{ textAlign: "left" }}>
                        <Typography variant="label">Email Address</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Your email address"
                        onChange={(e) => {
                          handleChange(e);
                          validateEmail(e.target.value);
                        }}
                        value={email}
                        required={isAddressEdited}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4} sx={{ mb: "3%" }}>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="phoneNumber"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography variant="label">Allow Patient to Login?</Typography>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="allowPatientToLogin"
                        value={allowPatientToLogin && allowPatientToLogin === true ? 'yes' : 'no'}
                        onChange={handleChange}
                      >
                        <FormControlLabel value="yes" control={<Radio sx={{
                          color: '#7B26C9',
                          '&.Mui-checked': {
                            color: '#7B26C9',
                          },
                        }}/>} label="Yes" />
                        <FormControlLabel value="no" control={<Radio sx={{
                          color: '#7B26C9',
                          '&.Mui-checked': {
                            color: '#7B26C9',
                          },
                        }} />} label="No" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                </Grid>

                <hr
                  style={{
                    borderTop: "1px solid #CCCCCC",
                    width: "100%",
                    margin: "8px 0",
                  }}
                />

                {/* Patient Details */}
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4} sx={{ mb: "3%" }}>
                    <Typography variant="buttonText2" gutterBottom>
                      Patient Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="firstName"
                        sx={{ textAlign: "left" }}
                        required
                      >
                        <Typography variant="label">First Name</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder="First name"
                        onChange={handleChange}
                        value={firstName}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="lastName"
                        sx={{ textAlign: "left" }}
                        required
                      >
                        <Typography variant="label">Last Name</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeholder="Last name"
                        onChange={handleChange}
                        value={lastName}
                        required
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel>
                        <Typography variant="label">Gender</Typography>
                      </FormLabel>
                      <Select
                        // labelId="gender-label"
                        id="gender"
                        name="gender"
                        required
                        defaultValue="" // Set default value to empty string
                        displayEmpty // This will make the placeholder visible
                        MenuProps={{
                          sx: {
                            "& .MuiPaper-root": {
                              backgroundColor: "#ffffff",
                            },
                            "& .MuiSelect-select": {
                              color: "grey",
                              backgroundColor: "#ffffff",
                              border: "none",
                            },
                            "& .MuiMenuItem-root": {
                              "&.Mui-focused": {
                                backgroundColor: "transparent",
                                outline: "#ffffff",
                              },
                            },
                          },
                        }}
                        value={
                          gender
                        }
                        onChange={handleChangeGender}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>{" "}
                        {/* Placeholder option */}
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="dateOfBirth"
                        sx={{ textAlign: "left" }}
                        required
                      >
                        <Typography variant="label">Date of Birth</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="dateOfBirth"
                        name="dateOfBirth"
                        type="date"
                        required
                        onChange={handleChangeDate}
                        value={dateOfBirth}
                        style={{ color: "#5B6B7C", opacity: 1 }}
                        inputProps={{ max:today}}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={4} sx={{ mb: "3%" }}></Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth margin="normal">
                          <FormLabel
                            htmlFor="aadhaarNumber"
                            sx={{ textAlign: "left" }}
                          >
                            <Typography variant="label">
                              Aadhaar Number
                            </Typography>
                          </FormLabel>
                          <OutlinedInput
                            id="aadhaarNumber"
                            name="aadhaarNumber"
                            type={showFullAadhaar ? 'text' : 'password'} // Toggle between text and password type
                            placeholder="Aadhaar Number"
                            value={aadhaarNumber}
                            onChange={handleAadhaarNumberChange}
                            endAdornment={
                              aadhaarNumber && (  // Only render if aadhaarNumber is not empty
                                <InputAdornment position="end">
                                  <IconButton onClick={handleToggleVisibility}>
                                    {showFullAadhaar ? (
                                      <VisibilityOutlinedIcon fontSize="small" />
                                    ) : (
                                      <VisibilityOffOutlinedIcon fontSize="small" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                            style= {{ borderColor: isAadhaarValid=== false ? '' : 'red' }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth margin="normal">
                          <FormLabel
                            htmlFor="panNumber"
                            sx={{ textAlign: "left" }}
                          >
                            <Typography variant="label">PAN Number</Typography>
                          </FormLabel>
                          <OutlinedInput
                            id="panNumber"
                            name="panNumber"
                            // type="text"
                            placeholder="PAN Number"
                            value={panNumber}
                            onChange={handlePanCardChange}
                            type={showFullPan ? 'text' : 'password'}
                            endAdornment={
                              panNumber && (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleToggleVisibilityForPan}>
                                    {showFullPan ? (
                                      <VisibilityOutlinedIcon fontSize="small" />
                                    ) : (
                                      <VisibilityOffOutlinedIcon fontSize="small" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }

                            style= {{ borderColor: isPanEdited=== false ? '' : 'red' }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={4} sx={{ mb: "3%" }}></Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth margin="normal">
                          <FormLabel
                            htmlFor="doctorAssigned"
                            sx={{ textAlign: "left" }}
                          >
                            <Typography variant="label">
                              Assigned Doctor
                            </Typography>
                          </FormLabel>
                          <OutlinedInput
                            id="doctorAssigned"
                            name="doctorAssigned"
                            type="text"
                            placeholder="Assigned Doctor"
                            value={doctorAssigned}
                            onChange={handleChange}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="referredBy"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography variant="label">Referred By</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="referredBy"
                        name="referredBy"
                        type="text"
                        placeholder="Referred By"
                        value={referredBy}
                        // value={userProfileData.emergency_contact ? userProfileData.emergency_contact : ''}
                        onChange={(e) => handleChange(e)}
                      />
                    </FormControl>
                  </Grid>
                    </Grid>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="emergencyContact"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography variant="label">
                          Emergency Contact Number
                        </Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="emergencyContact"
                        name="emergencyContact"
                        type="text"
                        placeholder="Emergency Contact Number"
                        // value={userProfileData.emergency_contact ? userProfileData.emergency_contact : ''}
                        onChange={(e) => handleNumericInputChange(e)}
                        value={emergencyContact}
                        inputProps={{
                          inputMode: "numeric", // Ensure numeric keyboard on mobile
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={8}>
                    <hr
                      style={{
                        borderTop: "1px solid #CCCCCC",
                        width: "100%",
                        margin: "8px 0",
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                  </Grid> */}
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="height"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography variant="label">
                          Height (In feet and inches)
                        </Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="height"
                        name="height"
                        type="text"
                        placeholder="Height (In feet and inches)"
                        value={height}
                        // value={userProfileData.emergency_contact ? userProfileData.emergency_contact : ''}
                        onChange={(e) => handleChange(e)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="weight"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography variant="label">Weight (In kgs)</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="weight"
                        name="weight"
                        type="text"
                        placeholder="Weight (In kgs)"
                        value={weight}
                        // value={userProfileData.emergency_contact ? userProfileData.emergency_contact : ''}
                        onChange={(e) => handleChange(e)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="bloodGroup"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography variant="label">Blood Group</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="bloodGroup"
                        name="bloodGroup"
                        type="text"
                        placeholder="Blood Group"
                        value={bloodGroup}
                        // value={userProfileData.emergency_contact ? userProfileData.emergency_contact : ''}
                        onChange={(e) => handleChange(e)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                </Grid>

                <hr
                  style={{
                    borderTop: "1px solid #CCCCCC",
                    width: "100%",
                    margin: "8px 0",
                  }}
                />

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4} sx={{ mb: "3%" }}>
                    <Typography variant="buttonText2" gutterBottom>
                      Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="address1"
                        sx={{ textAlign: "left" }}
                        required={isAddressEdited}
                        // required
                      >
                        <Typography variant="label">Address Line 1</Typography>
                      </FormLabel>
                      <OutlinedInput
                        {...getFieldProps("address1")}
                        id="address1"
                        name="address1"
                        type="text"
                        placeholder="Address Line 1"
                        onChange={(e) => handleChange(e)}
                        value={address1}
                        required={isAddressEdited}
                        // required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel htmlFor="address2" sx={{ textAlign: "left" }}>
                        <Typography variant="label">Address Line 2</Typography>
                      </FormLabel>
                      <OutlinedInput
                        // {...getFieldProps("address2")}
                        id="address2"
                        name="address2"
                        type="text"
                        placeholder="Address Line 2"
                        onChange={(e) => handleChange(e)}
                        value={address2}
                        // required={isAddressEdited}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="city"
                        sx={{ textAlign: "left" }}
                        required={isAddressEdited}
                        // required
                      >
                        <Typography variant="label">City</Typography>
                      </FormLabel>
                      <OutlinedInput
                        {...getFieldProps("city")}
                        id="city"
                        name="city"
                        type="text"
                        placeholder="City"
                        onChange={(e) => handleChange(e)}
                        value={city}
                        required={isAddressEdited}
                        // required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="state"
                        sx={{ textAlign: "left" }}
                        required={isAddressEdited}
                        // required
                      >
                        <Typography variant="label">State</Typography>
                      </FormLabel>
                      <Select
                        {...getFieldProps("state")}
                        labelId="state-select-label"
                        id="state-select"
                        value={state}
                        label="Select"
                        onChange={handleChangeState}
                        defaultValue="" // Set default value to empty string
                        displayEmpty // This will make the placeholder visible
                        MenuProps={{
                          sx: {
                            "& .MuiPaper-root": {
                              backgroundColor: "#ffffff",
                            },
                            "& .MuiSelect-select": {
                              color: "grey",
                              backgroundColor: "#ffffff",
                              border: "none",
                            },
                            "& .MuiMenuItem-root": {
                              "&.Mui-focused": {
                                backgroundColor: "transparent",
                                outline: "#ffffff",
                              },
                            },
                          },
                        }}
                        required={isAddressEdited}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="Gujarat">Gujarat</MenuItem>
                        <hr /> {/* Add a horizontal line as a partition */}
                        {indianStates.map((stateName, index) => (
                          <MenuItem key={index} value={stateName}>
                            {stateName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="zipcode"
                        sx={{ textAlign: "left" }}
                        required={isAddressEdited}
                        // required
                      >
                        <Typography variant="label">Zipcode</Typography>
                      </FormLabel>
                      <OutlinedInput
                        {...getFieldProps("zip")}
                        id="zipcode"
                        name="zipcode"
                        type="text"
                        placeholder="Zipcode"
                        onChange={(e) => handleZipInputChange(e)}
                        value={zip}
                        // required
                        required={isAddressEdited}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                </Grid>
              </Box>
              <hr
                style={{
                  borderTop: "1px solid #CCCCCC",
                  width: "100%",
                  margin: "8px 0",
                }}
              />
               <Grid container spacing={2} alignItems="center">
              {/* <Grid item xs={12} md={4}></Grid> */}
              <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                  <FormLabel>
                    <Typography variant="label">
                    Consent to Text
                    </Typography>
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ "&.Mui-checked": { color: "#7B26C9" } }}
                        checked={consentToText || false}
                      />
                    }
                    name="consentToText"
                    onChange={handleChange}
                    label={
                      <Typography variant="body1" sx={{ fontSize: 12, fontFamily: 'Inter' }}>
                        By checking the box, I agree to receive automated text
                        notifications.
                      </Typography>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ p: 2, marginLeft: "auto" }}
                  disabled={!isFormValid() || !( aadhaarNumber.length === 0 || aadhaarNumber.length === 14 ) || !( panNumber.length === 0 || panNumber.length === 10 ) } // Disable the button if the form is not valid
                  size="large"
                  onClick={(e) => handleSubmit(e)}
                >
                  <Typography variant="buttonText" sx={{ fontFamily: "Inter" }}>
                    Save
                  </Typography>
                </Button>
              </Box>
            </Container>
          </Box>
        </Container>
        </Box>
        </Box>
      </GreyBackgroundBox>
      {loading && <LoadingSpinner />}
    </ThemeProvider>
  );
};

export default PateintProfile;
