import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TableSortLabel from '@mui/material/TableSortLabel';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { styled } from '@mui/system';
import axios from "axios";
import Pagination from '@mui/material/Pagination';
import Button from "@mui/material/Button";
import clearSort from "../assets/clear-sort.svg";
import addPateint from "../assets/add-patient.svg";
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { useSnackbar } from './Snackbar';
import Tooltip from "@mui/material/Tooltip";
import LoadingSpinner from './LoadingSpinner'; 


// Define custom styles using makeStyles


export default function OutlinedCard() {
  const navigate = useNavigate();
  const useStyles = styled({
    cardContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px', // Ensure consistent padding
    },
    infoItem: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '0px',
    },
  });
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [docs, setDocs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(25); // Limit of documents per page
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [sortActive, setSortActive] = useState(false); // State to track sort activity

  const handleClearSort = () => {
    // Clear sorting state and apply default sorting
    setOrder('desc');
    setOrderBy('createdAt');
    setPage(1); // Reset page to 1
    getDocuments(1, limit); // Fetch documents with default sorting
    setSortActive(false); // Disable sorting flag
  };

  const token = Cookies.get('token');
 

  const getDocuments = async (page, limit) => {
    // console.log('token==>>',token)
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_ENVIRONMENT}/api/users`, {
        params: {
          sort_by: orderBy,
          sort_order: order,
          page,
          limit,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDocs(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false)
  };

  useEffect(() => {
    getDocuments(page, limit);
  }, [orderBy, order, page, limit]);


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setSortActive(true);
  };
  
  

  const handlePageChange = (event, value) => {
    setPage(value);
    // Fetch documents for the new page
    getDocuments(value, limit);
  };

  const handleCardClick = (value, patient_id) => {
    navigate("/patient-profile", {
      state: {
        userId: value,
        patient_id: patient_id
      },
    });
  };

  const Item = ({ data }) => {
    const formatDate = (inputDate) => new Date(inputDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    const formatPhoneNumber = (phoneNumber) => `+91 ${phoneNumber.slice(0, 5)} ${phoneNumber.slice(5)}`;

    const truncateData = (email, maxLength) => {
      if (!email) return '--';
    
      if (email.length > maxLength) {
        return email.slice(0, maxLength) + '...';
      } else {
        return email;
      }
    };

    return (
      <Card variant="outlined" sx={{ mb: 1, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', cursor: 'pointer', borderRadius: '6px' }} onClick={()=> handleCardClick(data._id, data.patient_id)}>
        <CardContent sx={{display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'}} style={{ marginTop: '0px', marginBottom: '0px', paddingBottom: '16px' }}>
          <Grid container spacing={2} alignItems="center"><Grid item xs={12} sm={2} md={4} lg={2}>
        {data.first_name && data.first_name.length > 20 ? (
          <Tooltip title={data.first_name} arrow>
            <Typography variant="body1"><strong>{truncateData(data.first_name, 20)}</strong></Typography>
          </Tooltip>
        ) : (
          <Typography variant="body1"><strong>{data.first_name || '-'}</strong></Typography>
        )}
      </Grid>

      <Grid item xs={12} sm={2} md={4} lg={2}>
        {data.last_name && data.last_name.length > 20 ? (
          <Tooltip title={data.last_name} arrow>
            <Typography variant="body1"><strong>{truncateData(data.last_name, 20)}</strong></Typography>
          </Tooltip>
        ) : (
          <Typography variant="body1"><strong>{data.last_name || '-'}</strong></Typography>
        )}
      </Grid>

            <Grid item xs={12} sm={2} md={4} lg={2}>
              <Typography variant="body2">{formatDate(data.dob)}</Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={4} lg={2}>
              <Typography variant="body2">{formatPhoneNumber(data.phone)}</Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={4} lg={2}>
      {data.email && data.email.length > 25 ? (
        <Tooltip title={data.email} arrow>
          <Typography variant="body2">{truncateData(data.email, 25)}</Typography>
        </Tooltip>
      ) : (
        <Typography variant="body2">{data.email || '-'}</Typography>
      )}
    </Grid>
            <Grid item xs={12} sm={2} md={4} lg={2}>
              <Typography variant="body2">{formatDate(data.createdAt)}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const SortingLabels = () => {
    const createSortHandler = (property) => () => {
      handleRequestSort(property);
    };

    return (
      <Box sx={{ mb: 1, backgroundColor: '#EDEFF3', padding: '8px 16px', borderRadius: '4px' }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TableSortLabel
              active={orderBy === 'first_name'}
              direction={orderBy === 'first_name' ? order : 'asc'}
              onClick={createSortHandler('first_name')}
              IconComponent={ArrowDropUpIcon}
              sx={{ '& svg': { fontSize: 24 } }}
            >
              <Typography variant="body1" color="textSecondary"><strong>First Name</strong></Typography>
            </TableSortLabel>
          </Grid>
          <Grid item xs={2}>
            <TableSortLabel
              active={orderBy === 'last_name'}
              direction={orderBy === 'last_name' ? order : 'asc'}
              onClick={createSortHandler('last_name')}
              IconComponent={ArrowDropUpIcon}
              sx={{ '& svg': { fontSize: 24 } }}
            >
              <Typography variant="body1" color="textSecondary"><strong>Last Name</strong></Typography>
            </TableSortLabel>
          </Grid>
          <Grid item xs={2}>
            {/* <TableSortLabel
              active={orderBy === 'dob'}
              direction={orderBy === 'dob' ? order : 'asc'}
              onClick={createSortHandler('dob')}
              IconComponent={ArrowDropUpIcon}
              sx={{ '& svg': { fontSize: 24 } }}
            > */}
              <Typography variant="body1" color="textSecondary"><strong>DOB</strong></Typography>
            {/* </TableSortLabel> */}
          </Grid>
          <Grid item xs={2}>
            {/* <TableSortLabel
              active={orderBy === 'phone'}
              direction={orderBy === 'phone' ? order : 'asc'}
              onClick={createSortHandler('phone')}
              IconComponent={ArrowDropUpIcon}
              sx={{ '& svg': { fontSize: 24 } }}
            > */}
              <Typography variant="body1" color="textSecondary"><strong>Phone Number</strong></Typography>
            {/* </TableSortLabel> */}
          </Grid>
          <Grid item xs={2}>
            {/* <TableSortLabel
              active={orderBy === 'email'}
              direction={orderBy === 'email' ? order : 'asc'}
              onClick={createSortHandler('email')}
              IconComponent={ArrowDropUpIcon}
              sx={{ '& svg': { fontSize: 24 } }}
            > */}
              <Typography variant="body1" color="textSecondary"><strong>Email</strong></Typography>
            {/* s</TableSortLabel> */}
          </Grid>
          <Grid item xs={2}>
            <TableSortLabel
              active={orderBy === 'createdAt'}
              direction={orderBy === 'createdAt' ? order : 'asc'}
              onClick={createSortHandler('createdAt')}
              IconComponent={ArrowDropUpIcon}
              sx={{ '& svg': { fontSize: 24 } }}
            >
              <Typography variant="body1" color="textSecondary"><strong>Original Referral Date</strong></Typography>
            </TableSortLabel>
          </Grid>

          {/* Similarly define sorting labels for other fields */}
        </Grid>
      </Box>
    );
  };

  return (
    <>
<Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on small screens, side-by-side on medium and larger screens
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 4,
        paddingLeft: 2,
        paddingRight: 2,
        mb: 2
      }}
    >
      <Button
        variant="contained"
        sx={{
          marginBottom: { xs: 1, md: 0 }, // Adjust margin bottom for responsiveness
          mt: { xs: 1, md: 0 }, // Adjust margin top for responsiveness
          width: { xs: '100%', md: 'auto' }, // Full width on small screens, auto on medium and larger screens
        }}
        size="large"
        onClick={() => navigate('/create-patient')}
        // startIcon={<img src={addPateint} alt="Add Patient" />}
      >
        <Typography
          variant="caption"
          sx={{
            fontSize: 14,
            fontFamily: 'Inter',
            fontWeight: 600,
          }}
        >
          Create Patient
        </Typography>
      </Button>
      <Button
        variant="outlined"
        sx={{
          marginBottom: { xs: 1, md: 0 },
          mt: { xs: 1, md: 0 },
          width: { xs: '100%', md: '150px' },
          '&:disabled img': {
            opacity: 0.5, // Reduce opacity for disabled state
          },
      
        }}
        size="large"
        onClick={handleClearSort}
          // startIcon={<img src={clearSort} alt="Clear Sort" />}
          disabled={!sortActive} // Disable if no sort is active
      >
        <Typography
          variant="caption"
          sx={{
            fontSize: 14,
            fontFamily: 'Inter',
            fontWeight: 600,
          }}
        >
          Clear Sort
        </Typography>
      </Button>
    </Box>
      <Box sx={{ minWidth: 275 }}>
        <SortingLabels />
        {docs && docs.length > 0 ? (
            docs.map((row, index) => (
              <Item key={row._id} data={row} />
            ))
          ) : (
            <Typography sx={{ ml:2}}>No Patient found.</Typography>
          )}

      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'right', mt: 4 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          sx={{

            '.MuiPaginationItem-page': {
              color: 'grey',
            },
            '.MuiPaginationItem-root.Mui-selected': {
              color: 'purple',
              backgroundColor: 'transparent',
              fontWeight: '700',
              fontFamily: "Inter",
              fontSize: 17,
              color: '#7B26C9'
            },
            '.MuiPaginationItem-ellipsis': {
              color: 'grey',
            },
            '.MuiPaginationItem-icon': {
              color: 'grey',
            },
          }}
        />
      </Box>
      {loading && <LoadingSpinner />}
    </>
  );
}
