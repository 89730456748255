import React, { useState, useEffect } from "react";
import {
  styled,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListItems, secondaryListItems } from './ListItems';
import useMediaQuery from "@mui/material/useMediaQuery";
import AdminList from "./AdminList";
import getCheckoutTheme from "./getCheckoutTheme";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import addPateint from "../assets/add-patient.svg";
import clearSort from "../assets/clear-sort.svg";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Pagination from '@mui/material/Pagination';
import Cookies from 'js-cookie';
import { useSnackbar } from './Snackbar';
import LoadingSpinner from './LoadingSpinner';

const drawerWidth = 72;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#ffffff",
    color: "black",
    boxShadow: "none",
    ...(open && {
      marginLeft: isMobile ? drawerWidth : drawerWidth,
      width: isMobile
        ? `calc(100% - ${drawerWidth}px)`
        : `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    ...(!open && {
      marginLeft: isMobile ? 0 : drawerWidth,
      width: isMobile ? "100%" : `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
  };
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return {
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: open ? drawerWidth : isMobile ? 0 : theme.spacing(7),
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: isMobile ? 0 : theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: isMobile ? 0 : theme.spacing(9),
        },
      }),
    },
  };
});

const defaultTheme = createTheme();
const checkoutTheme = createTheme(getCheckoutTheme("light"));

export default function Dashboard() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleSearchInputChange = (event, value) => {
    if (value !== "" && value.length >= 3) {
      setSearchQuery(value);
      getDocuments(value, 1);
    } else {
      setSearchQuery("");
      setPatients([]);
    }
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const settings = ["Logout"];

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // Handle search functionality here
    // console.log("Searching for:", searchQuery);
  };

  const handleMenuItemClick = (setting) => {
    if (setting === "Profile") {
      // navigate("/profile");
    } else if (setting === "Logout") {
      Cookies.remove("token");
      Cookies.remove("userData");
      navigate("/");
    }
  };

  const token = Cookies.get('token') ? Cookies.get('token') : navigate('/');
  const userData = Cookies.get('userData') ? JSON.parse(Cookies.get('userData')) : navigate('/');;
  // console.log('userData==>>',userData)

  const getDocuments = async (searchQuery) => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_ENVIRONMENT}/api/users`, {
        params: {
          search: searchQuery,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("response.data.data====>>", response.data.data, response.data.totalPages);
      setPatients(response.data.data);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false)
  };

  useEffect(() => {
    if (searchQuery.length >= 3) {
      getDocuments(searchQuery);
    }
  }, [searchQuery]);

  return (
    <ThemeProvider theme={checkoutTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <Typography
              variant="h5"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, fontFamily: "Inter",fontFamily: 600, cursor: 'pointer' }}
              onClick={()=> navigate('/home')}
            >
              Manage Users
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" sx={{ mr: 2, display: isMobile ? "none" : "block", fontFamily: 'Inter', cursor: 'pointer' }} onClick={handleOpenUserMenu}>
            <Tooltip title="Open settings">
              {userData ? userData.first_name + " " + userData.last_name : ""}
              </Tooltip>
            </Typography>
              <Typography variant="customFont2">
                <Tooltip title="Open settings">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0, color: "#7B26C9" }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
          </Box>

            <Menu
              sx={{
                mt: "45px",
                "& .MuiPaper-root": {
                  backgroundColor: "#ffffff",
                  minWidth: "10em",
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleMenuItemClick(setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          {/* <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar> */}
          <List component="nav" sx={{ mt: 7 }}>
          <MainListItems activePage="people" page="manage-admins"/>
        {secondaryListItems}
          </List>
        </Drawer>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? "#EDEFF3"
                : theme.palette.grey[900],
            flexGrow: 0,
            height: "100vh",
            overflow: "auto",
            width: "100vw",
          }}
        >
          <Toolbar />
          <Box sx={{ mt: 4, mb: 4, paddingLeft: 2, paddingRight: 2 }}>
            <Box>
              <AdminList />
            </Box>
          </Box>
        </Box>
      </Box>
      {loading && <LoadingSpinner />}
    </ThemeProvider>
  );
}
