import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  IconButton,
  Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSnackbar } from "./Snackbar";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import LoadingSpinner from './LoadingSpinner';

const PatientDetails = ({ data }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { showSnackbar } = useSnackbar();
  const [showOriginal, setShowOriginal] = useState(false);
  const [aadhaarMasked, setAadhaarMasked] = useState("");
  const [showOriginalPan, setShowOriginalPan] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const maskAadhaar = (aadhaar) => {
    const maskedPart = aadhaar.slice(-4);
    return `****-****-${maskedPart}`;
  };

  const handleToggleVisibility = () => {
    setShowOriginal(!showOriginal);
    setTimeout(() => {
      setShowOriginal(false);
    }, 10000);
  };

  const handleToggleVisibilityForPan = () => {
    setShowOriginalPan(!showOriginalPan);
    setTimeout(() => {
      setShowOriginalPan(false);
    }, 10000);
  };

  useEffect(() => {
    if (data.aadhaar_number) {
      setAadhaarMasked(data.aadhaar_number!== undefined ? maskAadhaar(data.aadhaar_number) : '--');
    }
  }, [data.aadhaar_number]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = (data) => {
    if (data && data !== undefined) {
      const textField = document.createElement("textarea");
      textField.innerText = data;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      showSnackbar(`Copied`, "success");
    }
  };

  const formatDOB = (dob) => {
    var dobDate = new Date(dob);
    var formattedDOB = dobDate.toLocaleString("en-us", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    var ageDate = new Date(Date.now() - dobDate);
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);
    var formattedDOBWithAge = formattedDOB + " (" + age + " yrs)";
    return formattedDOBWithAge;
  };

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const options = { month: "long", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatAddress = (address) => {
    if (!address) return false;
  
    const { address1, address2, city, state, zip } = address;
  
    let formattedAddress = `${address1}`;
    if (address2) {
      formattedAddress += `, ${address2}`;
    }
    formattedAddress += `,\n${city}, ${state} ${zip}`;
  
    return formattedAddress;
  };
  

  const handleEditProfile = () => {
    navigate("/edit-profile", {
      state: {
        userId: data._id,
        patient_id: data.patient_id
      },
    });
  };

  const token = Cookies.get("token");

  const getUserDetails = async (page, limit) => {
    // console.log('token==>>',token)
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENVIRONMENT}/api/users`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, "error");
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, "error");
        }
      } else {
        showSnackbar(error.message, "error");
        // showSnackbar(error.response.data.message, 'error');
      }
    }
    setLoading(false)
  };

  const deletePatient = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_ENVIRONMENT}/api/users/${data._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSnackbar(`Patient is deleted.`, "success");
      navigate("/home");
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, "error");
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, "error");
        }
      } else {
        showSnackbar(error.message, "error");
        // showSnackbar(error.response.data.message, 'error');
      }
    }
  };

  function insertDashes(inputString) {
    return inputString.replace(/(.{4})/g, "$1-").slice(0, -1);
  }

  const truncateData = (email, maxLength) => {
    if (!email) return "--";

    if (email.length > maxLength) {
      return email.slice(0, maxLength) + "...";
    } else {
      return email;
    }
  };

  const fullName =
    data.first_name && data.last_name
      ? `${data.first_name} ${data.last_name}`
      : "";

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#1E1E2F",
        color: "white",
        width: "100%",
        height: "100%",
        fontFamily: "Inter",
        borderTopLeftRadius: "7px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="customFont3">
          {data.length !== 0 ? (
            fullName.length > 25 ? (
              <Tooltip title={fullName} arrow>
                {truncateData(fullName, 25)}
              </Tooltip>
            ) : (
              fullName || "--"
            )
          ) : (
            ""
          )}
        </Typography>

        <IconButton
          sx={{
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)", // Change background color on hover
            },
          }}
          onClick={handleMenuClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          //   PaperProps={{
          //     style: {
          //       backgroundColor: '#2C2C3E',
          //       color: 'white',
          //     },
          //   }}
        >
          <MenuItem onClick={handleEditProfile}>Edit Profile</MenuItem>
          <MenuItem onClick={deletePatient} sx={{ color: "red" }}>
            Delete Patient
          </MenuItem>
        </Menu>
      </Box>
      <Grid container spacing={2} sx={{ pt: 0 }}>
        {/* ID and Copy Icon */}
        <Grid item xs={12} sx={{ pt: 0 }}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 0 }}>
            <Typography variant="customFont5" sx={{ mb: 1 }}>
              {data.length !== 0 ? data.patient_id : ""}
            </Typography>
            {data.patient_id && (
              <IconButton
                size="small"
                sx={{ ml: "2px", mb: 1 }}
                onClick={() => handleCopy(data.patient_id)}
              >
                <CopyIcon
                  fontSize="small"
                  sx={{ color: "#ffffff", fontSize: "18px" }}
                />
              </IconButton>
            )}
          </Box>
        </Grid>

        {/* Gender and DOB */}
        <Grid container item xs={6}>
          <Grid item xs={12}>
            <Typography variant="customFont4" sx={{ mb: 1 }}>
              Gender:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", mt: 0 }}
          >
            <Typography variant="customFont5">
              {data.length !== 0 && data.gender
                ? data.gender.charAt(0).toUpperCase() + data.gender.slice(1)
                : "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", mt: 0 }}
          >
            <Typography variant="customFont4" sx={{ mb: 1 }}>
              DOB:
            </Typography>
            {data.dob && (
              <IconButton
                size="small"
                sx={{ ml: "2px", mb: 1 }}
                onClick={() =>
                  data.length !== 0 && data.dob
                    ? handleCopy(formatDOB(data.dob))
                    : "--"
                }
              >
                <CopyIcon
                  fontSize="small"
                  sx={{ color: "#ffffff", fontSize: "18px" }}
                />
              </IconButton>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", mt: 0 }}
          >
            <Typography variant="customFont5">
              {data.length !== 0 && data.dob ? formatDOB(data.dob) : "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          {/* Grid item for label and icon */}
          <Grid item xs={12}>
            <Typography variant="customFont4" sx={{ mb: 1 }}>
              Aadhaar Number:
            </Typography>
            <IconButton
              size="small"
              sx={{ ml: "2px", mb: 0 }}
              onClick={handleToggleVisibility}
              disabled={!data.aadhaar_number}
            >
              {data.aadhaar_number && (
                showOriginal ? (
                  <VisibilityOffOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#ffffff", fontSize: "18px" }}
                  />
                ) : (
                  <VisibilityOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#ffffff", fontSize: "18px" }}
                  />
                )
              )}
            </IconButton>
          </Grid>
          {/* Grid item for Aadhaar number value */}
          <Grid item xs={12}>
            <Typography variant="customFont5">
              {showOriginal ? data.aadhaar_number : data.aadhaar_number ? aadhaarMasked : '--'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          {/* Grid item for label and icon */}
          <Grid item xs={12}>
            <Typography variant="customFont4" sx={{ mb: 1 }}>
            PAN Number:
            </Typography>
            <IconButton
              size="small"
              sx={{ ml: "2px", mb: 0 }}
              onClick={handleToggleVisibilityForPan}
              disabled={!data.pan_number}
            >
              {data.pan_number && (
                showOriginalPan ? (
                  <VisibilityOffOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#ffffff", fontSize: "18px" }}
                  />
                ) : (
                  <VisibilityOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#ffffff", fontSize: "18px" }}
                  />
                )
              )}
            </IconButton>
          </Grid>
          {/* Grid item for Pan number value */}
          <Grid item xs={12}>
            <Typography variant="customFont5">
              {showOriginalPan
                ? data.pan_number
                : data.pan_number
                ? "******" + data.pan_number.slice(-4)
                : "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12}>
            <Typography variant="customFont4" sx={{ mb: 1 }}>
              Height:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="customFont5">
              {data.length !== 0 && data.height ? data.height : "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12}>
            <Typography variant="customFont4" sx={{ mb: 1 }}>
              Weight:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="customFont5">
              {data.length !== 0 && data.weight ? data.weight : "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12}>
            <Typography variant="customFont4" sx={{ mb: 1 }}>
              Blood Group:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="customFont5">
              {data.length !== 0 && data.blood_group ? data.blood_group : "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <Typography variant="customFont4" sx={{ mb: 1 }}>DOB: Feb 2, 1990 (32 yrs)</Typography> */}
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        sx={{ mb: -1.3 }}
        TabIndicatorProps={{
          style: { backgroundColor: "#7B26C9", height: 5, mr: 1 },
        }}
      >
        <Tab
          label={
            <Typography
              sx={{
                textTransform: "none",
                color: tabIndex === 0 ? "#ffffff" : "#BAC3CE",
                fontFamily: "Inter",
                // color: tabValue === "uploaded" ? "secondary.custom" : "#525968",
                fontWeight: tabIndex === 0 ? 600 : 400,
                fontSize: 14,
                // fontFamily: tabValue === "uploaded" ? "Riona Sans W01 Bold" : "",
              }}
            >
              General
            </Typography>
          }
          style={{ minWidth: "50%" }}
          sx={{
            "&:hover": {
              color: "#7B26C9", // Text color on hover
              opacity: 0.7, // Reduce opacity on hover
              borderBottom: "2px solid #7B26C9", // Bottom border on hover
              transition: "all 0.3s ease", // Smooth transition
            },
          }}
        />
        <Tab
          label={
            <Typography
              sx={{
                textTransform: "none",
                color: tabIndex === 1 ? "#ffffff" : "#BAC3CE",
                fontFamily: "Inter",
                // color: tabValue === "uploaded" ? "secondary.custom" : "#525968",
                fontWeight: tabIndex === 1 ? 800 : 400,
                fontSize: 14,
                // fontFamily: tabValue === "uploaded" ? "Riona Sans W01 Bold" : "",
              }}
            >
              Contact
            </Typography>
          }
          style={{ minWidth: "50%" }}
          sx={{
            "&:hover": {
              color: "#7B26C9", // Text color on hover
              opacity: 0.7, // Reduce opacity on hover
              borderBottom: "2px solid #7B26C9", // Bottom border on hover
              transition: "all 0.3s ease", // Smooth transition
            },
          }}
        />
        {/* <Tab label="Timeline" sx={{ minWidth: 0 }} /> */}
      </Tabs>
      <hr
        style={{
          borderTop: "1px solid #BAC3CE",
          width: "100%",
          margin: "8px 0",
        }}
      />

      {tabIndex === 0 && (
        <Box>
                 <Box sx={{ display: "flex", alignItems: "center", mb: 2, mt: 2 }}>
            {data.allow_login ? (
              <CheckCircleIcon sx={{ color: "#00D65B", mr: 1 }} />
            ) : (
              <HighlightOffIcon sx={{ color: "#f1c40f", mr: 1 }} />
            )}
            <Typography
              variant="customFont4"
              sx={{
                color: data.allow_login ? "#00D65B" : "#f1c40f",
                fontWeight: 800,
              }}
            >
              {data.allow_login
                ? "Patient Allowed to Login"
                : "Patient Not Allowed to Login"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2, mt: 1 }}>
            {data.has_consent_enabled ? (
              <CheckCircleIcon sx={{ color: "#00D65B", mr: 1 }} />
            ) : (
              <HighlightOffIcon sx={{ color: "#f1c40f", mr: 1 }} />
            )}
            <Typography
              variant="customFont4"
              sx={{
                color: data.has_consent_enabled ? "#00D65B" : "#f1c40f",
                fontWeight: 800,
              }}
            >
              {data.has_consent_enabled
                ? "Opted into SMS"
                : "Not opted into SMS"}
            </Typography>
          </Box>

          <Grid container spacing={2}>
            {" "}
            {/* Add spacing prop to provide spacing between Grid items */}
            <Grid container item xs={6}>
              <Grid item xs={12}>
                <Typography variant="customFont4">
                Assigned Doctor 
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="customFont5" sx={{ fontWeight: "bold" }}>
                  {data.length !== 0 && data.assigned_doctor
                    ? data.assigned_doctor
                    : "--"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 0}}>
            {" "}
            {/* Add spacing prop to provide spacing between Grid items */}
            <Grid container item xs={6}>
              <Grid item xs={12}>
                <Typography variant="customFont4">
                  Original Referral Date
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="customFont5" sx={{ fontWeight: "bold" }}>
                  {data.length !== 0 && data.createdAt
                    ? formatDate(data.createdAt)
                    : "--"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={6}>
              <Grid item xs={12}>
                <Typography variant="customFont4">Referred By</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="customFont5" sx={{ fontWeight: "bold" }}>
                  {data.length !== 0 && data.referred_by
                    ? data.referred_by
                    : "--"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="customFont4">Email</Typography>
              {data.email && (
                <IconButton
                  size="small"
                  sx={{ ml: "2px", mb: 0 }}
                  onClick={() => handleCopy(data.email)}
                >
                  <CopyIcon
                    fontSize="small"
                    sx={{ color: "#ffffff", fontSize: "18px" }}
                  />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="customFont5" sx={{ fontWeight: "bold" }}>
                {/* {data.length !== 0 && data.email ? truncateData(data.email,40) : "--"} */}
                {data.length !== 0 ? (
            data.email.length > 40 ? (
              <Tooltip title={data.email} arrow>
                {truncateData(data.email, 40)}
              </Tooltip>
            ) : (
              data.email || "--"
            )
          ) : (
            ""
          )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Typography variant="customFont4">Phone Number</Typography>
              {data.phone && (
                <IconButton
                  size="small"
                  sx={{ ml: "2px", mb: 0 }}
                  onClick={() => handleCopy(data.phone)}
                >
                  <CopyIcon
                    fontSize="small"
                    sx={{ color: "#ffffff", fontSize: "18px" }}
                  />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="customFont5" sx={{ fontWeight: "bold" }}>
                {data.length !== 0 ? data.phone : "--"}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid container>
  
</Grid> */}
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Typography variant="customFont4">Address</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="customFont5" sx={{ fontWeight: "bold" }}>
                {!data.address ||
                Object.values(data.address).every((value) => value === "")
                  ? "--"
                  : formatAddress(data.address)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {loading && <LoadingSpinner />}
      {/* {tabIndex === 2 && <Box><Typography>Timeline Information</Typography></Box>} */}
    </Box>
  );
};

export default PatientDetails;
