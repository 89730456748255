import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  InputAdornment,
  OutlinedInput,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CssBaseline from "@mui/material/CssBaseline";
import getCheckoutTheme from "./getCheckoutTheme";
import { styled } from "@mui/system";
import axios from "axios";
import AppBar from "./AppBar3";
import Cookies from 'js-cookie';
import { useSnackbar } from './Snackbar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import LoadingSpinner from './LoadingSpinner'; 

const GreyBackgroundBox = styled(Box)({
  backgroundColor: "#edeff3",
  minHeight: "100vh",
  minWidth: '100vw',
});

const AdminRegistration = () => {

  const checkoutTheme = createTheme(getCheckoutTheme("light"));
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email,setEmail] = useState('');
  const [phone,setPhone] = useState('');
  const { showSnackbar } = useSnackbar();
//   const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);


  const [emailError, setEmailError] = useState(false);
  const [emailBorderColor, setEmailBorderColor] = useState('');
  
  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  
    const isValid = emailRegex.test(email);
    setEmailError(!isValid);
    // setEmailBorderColor(isValid ? '' : 'red');
  
    return isValid;
  };

  const token = Cookies.get('token');
  if (!token) {
    navigate('/');
  }

  const isFormValid = () => {
    if (firstName !== "" && lastName !== "" && email !== "" ) {
      return true;
    }
    return false;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'email':
        setEmail(value);
        validateEmail(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      default:
        break;
    }
  };
  

  const getFieldProps = (fieldName) => {
    const isEmailField = fieldName === "email";
    let value;
  
    switch (fieldName) {
      case "email":
        value = email;
        break;
      case "firstName":
        value = firstName;
        break;
      case "lastName":
        value = lastName;
        break;
    //   case "password":
    //     value = password;
    //     break;
      default:
        value = "";
    }
  
    return {
      name: fieldName,
      onChange: handleChange,
      value: value,
      error: isEmailField && emailError,
      helperText: isEmailField && emailError ? "Invalid email address" : "",
      style: {
        borderColor: isEmailField && emailBorderColor,
      },
    };
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENVIRONMENT}/api/users/admin`, {
       email,
       first_name: firstName,
       last_name: lastName,
      //  phone: phone
    //    password,
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        showSnackbar(`Admin user created successfully.`, 'success');
    //   getUser();
      navigate('/manage-users');
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false);
  };

  const handleNumericInputChangeForPhone = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 10);
    setPhone(numericValue);
  };

  const handleCardClick = () => {
    navigate("/manage-users")
  };
  
  return (
    <ThemeProvider theme={checkoutTheme}>
      <GreyBackgroundBox>
      <Box sx={{ display: "flex" }}>
      <CssBaseline />
        <AppBar mainPage="Manage Users" currentPage="Create Admin" pageUrl="/manage-users"/>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? "#EDEFF3"
                : theme.palette.grey[900],
            flexGrow: 0,
            height: "100vh",
            overflow: "auto",
            width: "100vw",
            // mt: 5,
          }}
        >
        <Box sx={{ mt: 2, mb: 0, display: 'flex', alignItems: 'center', ml: 3 }}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{ color: '#7B26C9', ml: -1, mr: -1 }} />} aria-label="breadcrumb">
        <Typography
          component="div"
          sx={{
            color: '#7B26C9',
            textDecoration: 'none',
            cursor: 'pointer',
            borderBottom: '2px solid #7B26C9',
            display: 'inline-block',
            '&:hover': {
              textDecoration: 'none',
            },
          }}
          onClick={handleCardClick}
        >
          Manage Users
        </Typography>
        <Typography color="text.primary" sx={{ color: '#7B26C9' }}>Create Admin</Typography>
      </Breadcrumbs>
    </Box>

        <Container sx={{ backgroundColor: "#ffffff", pb: 1,mt: 8, }}>
          <Box sx={{ mt: 4 }}>
            <Container
              maxWidth="md"
              sx={{ bgcolor: "#ffffff", p: 4, mt: 4, mb: 4, borderRadius: 2 }}
            >
              <Box component="form" noValidate autoComplete="off">
                <hr
                  style={{
                    borderTop: "1px solid #CCCCCC",
                    width: "100%",
                    margin: "8px 0",
                  }}
                />


                {/* Patient Details */}
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4} sx={{ mb: '3%'}}>
                    <Typography variant="buttonText2" gutterBottom>
                      Admin Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="firstName"
                        sx={{ textAlign: "left" }}
                        required
                      >
                        <Typography variant="label">
                          First Name
                        </Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder="First name"
                        onChange={handleChange}
                        // value={userProfileData.first_name ? userProfileData.first_name : ''}
                        required
                        value={firstName}
                        // disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="lastName"
                        sx={{ textAlign: "left" }}
                        required
                      >
                        <Typography variant="label">
                          Last Name
                        </Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeholder="Last name"
                        onChange={handleChange}
                        // value={userProfileData.last_name ? userProfileData.last_name : ''}
                        required
                        value={lastName}
                        // disabled
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}></Grid>
                  {/* <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel
                        htmlFor="phoneNumber"
                        sx={{ textAlign: "left" }}
                        required
                      >
                        <Typography variant="label">Phone Number</Typography>
                      </FormLabel>
                      <OutlinedInput
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone Number"
                        onChange={handleNumericInputChangeForPhone}
                        value={phone}
                        required
                        inputProps={{
                          inputMode: "numeric", // Ensure numeric keyboard on mobile
                        }}
                        // disabled
                      />
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel htmlFor="email" sx={{ textAlign: "left" }} required>
                      <Typography variant="label">
                          Email Address
                      </Typography>
                      </FormLabel>
                      <OutlinedInput
                        {...getFieldProps('email')}
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        onChange={(e) => {
                          handleChange(e);
                          validateEmail(e.target.value);
                        }}
                        value={email}
                        // required={isAddressEdited}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <hr
                style={{
                  borderTop: "1px solid #CCCCCC",
                  width: "100%",
                  margin: "8px 0",
                }}
              />
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ p: 2, marginLeft: "auto" }}
                  disabled={!isFormValid()} // Disable the button if the form is not valid
                  size="large"
                  onClick={(e)=> handleSubmit(e)}
                >
                  <Typography variant="buttonText" sx={{ fontFamily: 'Inter'}}>Create Admin</Typography>
                </Button>
              </Box>
            </Container>
          </Box>
        </Container>
        </Box>
        </Box>
      </GreyBackgroundBox>
      {loading && <LoadingSpinner />}
    </ThemeProvider>
  );
};

export default AdminRegistration;
