import React from 'react';

const PurplegradientIcon = ({ className }) => (
  <svg
    className={className}
    preserveAspectRatio='none'
    viewBox='0 0 1440 900'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    width="100%"
    height="100%"
    overflow="hidden"
  >
    <g opacity="0.87" clipPath='url(#clip0_67_25979)'>
      <rect width="100%" height="100%" transform='matrix(0 -1 1 0 0 900)' fill='#131126' />
      <g filter='url(#filter0_d_67_25979)'>
        <g filter='url(#filter1_f_67_25979)'>
          <path
            d='M685.408 71.5822C945.207 74.7772 1150.55 287.927 1144.06 547.665V547.665C1137.56 807.403 921.688 1015.37 661.889 1012.18L264.284 1007.29C4.48478 1004.09 -200.859 790.943 -194.365 531.205V531.205C-187.87 271.467 28.004 63.4976 287.804 66.6926L685.408 71.5822Z'
            fill='#00AEEF'
          />
          <path
            d='M685.408 71.5822C945.207 74.7772 1150.55 287.927 1144.06 547.665V547.665C1137.56 807.403 921.688 1015.37 661.889 1012.18L264.284 1007.29C4.48478 1004.09 -200.859 790.943 -194.365 531.205V531.205C-187.87 271.467 28.004 63.4976 287.804 66.6926L685.408 71.5822Z'
            stroke='#82BE37'
            strokeWidth={61.9461}
          />
        </g>
        <g
          style={{
            mixBlendMode: 'color',
          }}
          filter='url(#filter2_f_67_25979)'
        >
          <rect
            width={881.318}
            height={1235.93}
            rx={440.659}
            transform='matrix(-0.20062 0.979669 -0.994966 -0.100211 1035.03 -64.9844)'
            fill='#82BE37'
          />
        </g>
        <g
          style={{
            mixBlendMode: 'screen',
          }}
          filter='url(#filter3_f_67_25979)'
        >
          <path
            d='M1294.61 244.051C1640.87 335.395 1784.35 665.271 1615.09 980.851L1606.31 997.212C1437.04 1312.79 1019.13 1494.57 672.863 1403.23L328.999 1312.52C-17.2637 1221.17 -160.747 891.296 8.51878 575.717L17.2941 559.356C186.56 243.776 604.478 61.9972 950.741 153.341L1294.61 244.051Z'
            fill='url(#paint0_radial_67_25979)'
          />
        </g>
        <g filter='url(#filter4_f_67_25979)'>
          <path
            d='M403.745 -217.746C626.382 -406.729 1011.7 -441.244 1264.38 -294.839L1327.23 -258.424C1579.91 -112.018 1604.27 159.868 1381.63 348.851V348.851C1158.99 537.834 773.671 572.349 520.991 425.944L458.142 389.528C205.462 243.123 181.108 -28.7635 403.745 -217.746V-217.746Z'
            fill='url(#paint1_angular_67_25979)'
          />
        </g>
        <g
          style={{
            mixBlendMode: 'lighten',
          }}
          filter='url(#filter5_f_67_25979)'
        >
          <rect
            width={1335.69}
            height={1873.12}
            rx={667.846}
            transform='matrix(-0.20062 0.979669 -0.994966 -0.100211 1707.21 -413.99)'
            fill='#7B26C9'
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id='filter0_d_67_25979'
        x={-307.978}
        y={-538.237}
        width={1998.12}
        height={1973.07}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_67_25979' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_67_25979' result='shape' />
      </filter>
      <filter
        id='filter1_f_67_25979'
        x={-520.728}
        y={-259.542}
        width={1991.15}
        height={1597.95}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={147.616} result='effect1_foregroundBlur_67_25979' />
      </filter>
      <filter
        id='filter2_f_67_25979'
        x={-575.949}
        y={-430.926}
        width={1815.45}
        height={1471.43}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={141.981} result='effect1_foregroundBlur_67_25979' />
      </filter>
      <filter
        id='filter3_f_67_25979'
        x={-386.746}
        y={-194.474}
        width={2397.1}
        height={1945.52}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={162.103} result='effect1_foregroundBlur_67_25979' />
      </filter>
      <filter
        id='filter4_f_67_25979'
        x={-117.329}
        y={-754.337}
        width={2020.03}
        height={1639.78}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={184.404} result='effect1_foregroundBlur_67_25979' />
      </filter>
      <filter
        id='filter5_f_67_25979'
        x={-653.226}
        y={-887.486}
        width={2589.2}
        height={2067.82}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={174.624} result='effect1_foregroundBlur_67_25979' />
      </filter>
      <radialGradient
        id='paint0_radial_67_25979'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(811.802 778.284) rotate(-165.222) scale(826.226 2034.19)'
      >
        <stop stopColor='#8B78FF' />
        <stop offset={1} stopColor='#6349FF' stopOpacity={0.37} />
      </radialGradient>
      <radialGradient
        id='paint1_angular_67_25979'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(892.687 65.5524) rotate(139.674) scale(528.768 532.391)'
      >
        <stop stopColor='#FFE072' />
        <stop offset={1} stopColor='#FFD84B' stopOpacity={0} />
      </radialGradient>
      <clipPath id='clip0_67_25979'>
        <rect width={900} height={1440} fill='white' transform='matrix(0 -1 1 0 0 900)' />
      </clipPath>
    </defs>
  </svg>
);

export default PurplegradientIcon;
