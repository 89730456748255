import React, { useState, useEffect } from "react";
import {
  styled,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { MainListItems, secondaryListItems } from './ListItems';
import useMediaQuery from "@mui/material/useMediaQuery";
import Orders from "./Orders";
import getCheckoutTheme from "./getCheckoutTheme";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Cookies from 'js-cookie';
import { useSnackbar } from './Snackbar';
import LoadingSpinner from './LoadingSpinner'; 

const drawerWidth = 72;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#ffffff",
    color: "black",
    boxShadow: "none",
    ...(open && {
      marginLeft: isMobile ? drawerWidth : drawerWidth,
      width: isMobile
        ? `calc(100% - ${drawerWidth}px)`
        : `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    ...(!open && {
      marginLeft: isMobile ? 0 : drawerWidth,
      width: isMobile ? "100%" : `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
  };
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return {
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: open ? drawerWidth : isMobile ? 0 : theme.spacing(7),
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: isMobile ? 0 : theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: isMobile ? 0 : theme.spacing(9),
        },
      }),
    },
  };
});

const defaultTheme = createTheme();
const checkoutTheme = createTheme(getCheckoutTheme("light"));

export default function Dashboard() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // 'No patient found' option
  const noPatientOption = {
    first_name: "No patients found",
    last_name: '',
    disabled: true, // Marking the option as disabled
  };

  // const handleSearchInputChange = (event, value) => {
  //   const inputValue = event.target.value;
  //   const sanitizedValue = value?.replace(/[\\^$*+?.()|[\]{}]/g, '');
    
  //   if (sanitizedValue !== "" && sanitizedValue.length >= 3) {
  //     setSearchQuery(sanitizedValue);
  //     setSelectedOption(null);
  //     getDocuments(sanitizedValue, 1);
  //   } else {
  //     setSearchQuery("");
  //     setPatients([]);
  //   }
  // };

  const handleSearchInputChange = (event, value) => {
    const inputValue = event.target.value;
    const sanitizedValue = value?.replace(/[\\^$*+?()|[\]{}]/g, '');
    if (sanitizedValue !== "" && sanitizedValue.length >= 3 && inputValue.length >= 3) {
      setSearchQuery(inputValue);
      setSelectedOption(null);
      getDocuments(inputValue, 1);
    } else if (sanitizedValue !== "" && sanitizedValue.length >= 3 && inputValue.length > 0 && inputValue.length < 3) {
      setSearchQuery('');
      setPatients([noPatientOption]); // Show "No patient found" for lengths 1 and 2
    } else {
      setSearchQuery('');
      setPatients([]); // Reset patients state for empty query
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const settings = ["Manage Users","Logout"];

  const handleSearchSubmit = (event) => {
    event.preventDefault();
  };

  const handleOptionSelect = (val) => {
    if(val!== null && val._id!== undefined && val._id!== 'undefined' && val._id!== null){
          navigate("/patient-profile", {
      state: {
        userId: val._id,
        patient_id: val.patient_id
      },
    });
    }
  };

  
  const handleMenuItemClick = (setting) => {
    if (setting === "Manage Users") {
      navigate("/manage-users");
    } else if (setting === "Logout") {
      Cookies.remove("token");
      Cookies.remove("userData");
      navigate("/");
    }
  };

  const token = Cookies.get('token') ? Cookies.get('token') : navigate("/");
  const userData = Cookies.get('userData') ? JSON.parse(Cookies.get('userData')) :  navigate("/");

  const getDocuments = async (searchQuery) => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_ENVIRONMENT}/api/users`, {
        params: {
          search: searchQuery,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPatients(response.data.data);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    }
    setLoading(false)
  };

  const getOptionLabel = (option) => {
    if (!option) return '';

    const { first_name, last_name } = option;
    if (first_name && last_name) {
      return `${first_name} ${last_name}`;
    } else {
      return '';
    }
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSelectedOption(null);
      setSearchQuery(event.target.value);
    }
  };

  const [filteredPatients, setFilteredPatients] = useState([]);

  const getOptionLabelWithFallback = (option) => {
    const firstName = option?.first_name || "";
    const lastName = option?.last_name || "";
    return `${firstName} ${lastName}`.trim();
  }  
 

  useEffect(() => {
    if (searchQuery.length >= 3) {
      getDocuments(searchQuery);
    } else if (searchQuery.length > 0 && searchQuery.length < 3) {
      setPatients([noPatientOption]); // Show "No patient found" for lengths 1 and 2
    } else {
      setPatients([]); // Reset patients state for empty query
    }
  }, [searchQuery]);

  return (
    <ThemeProvider theme={checkoutTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <Typography
              variant="h5"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, fontFamily: "Inter", fontWeight: 600 }}
            >
              Patients
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
            <Autocomplete
              freeSolo
              options={patients}
              getOptionLabel={getOptionLabelWithFallback}
              getOptionDisabled={(option) => option.disabled === true}
              filterOptions={(options, { inputValue }) => {
                const filteredOptions = options.filter(option => {
                  const fullName = `${option.first_name} ${option.last_name}`.toLowerCase();
                  const searchValue = inputValue.toLowerCase();
                  return (
                    fullName.includes(searchValue) ||
                    option.first_name?.toLowerCase().includes(searchValue) ||
                    option.email?.toLowerCase().includes(searchValue) ||
                    option.phone?.toLowerCase().includes(searchValue) ||
                    option.aadhaar_number?.toLowerCase().includes(searchValue) ||
                    option.pan_number?.toLowerCase().includes(searchValue) ||
                    option.patient_id?.toLowerCase().includes(searchValue)
                  );
                });
              
                return inputValue.length >= 3 && filteredOptions.length === 0
                  ? [noPatientOption]
                  : filteredOptions;
              }}
              onInputChange={handleSearchInputChange}
              onChange={(event, value) => {
                if (value?.first_name === 'No patients found' || value === null) {
                  return;
                }
                setSelectedOption(value);
                handleOptionSelect(value, event);
              }}
              sx={{
                width: isMobile ? '12rem' : '20rem',
                mr: isMobile ? 1 : 5,
              }}
              renderInput={(params) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '1px 16px',
                    marginRight: 2,
                  }}
                >
                  <TextField
                    {...params}
                    placeholder="Search Patients"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      sx: {
                        '&::placeholder': {
                          color: '#7B8291',
                          fontFamily: 'Inter',
                        },
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ color: '#7B8291', mr: 1 }} />
                        </InputAdornment>
                      ),
                      onKeyDown: handleKeyDown,
                    }}
                  />
                </Box>
              )}
            />

              <Typography
                variant="body1"
                sx={{
                  mr: 2,
                  display: isMobile ? "none" : "block",
                  fontFamily: "Inter",
                  cursor: "pointer",
                }}
                onClick={handleOpenUserMenu}
              >
                <Tooltip title="Open settings">
                  {userData
                    ? userData.first_name + " " + userData.last_name
                    : ""}
                </Tooltip>
              </Typography>
              <Typography variant="customFont2">
                <Tooltip title="Open settings">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0, color: "#7B26C9" }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Box>

            <Menu
              sx={{
                mt: "45px",
                "& .MuiPaper-root": {
                  backgroundColor: "#ffffff",
                  minWidth: "10em",
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) =>
                // Check if the setting is 'Manage user setting' and user role is 'superadmin'
                setting === "Manage Users" &&
                userData &&
                userData.role !== "superadmin" ? null : (
                  <MenuItem
                    key={setting}
                    onClick={() => handleMenuItemClick(setting)}
                  >
                    <Typography textAlign="center" sx={{ fontFamily: "Inter" }}>
                      {setting}
                    </Typography>
                  </MenuItem>
                )
              )}
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          {/* <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar> */}
          <List component="nav" sx={{ mt: 7 }}>
            <MainListItems activePage="home" />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? "#EDEFF3"
                : theme.palette.grey[900],
            flexGrow: 0,
            height: "100vh",
            overflow: "auto",
            width: "100vw",
          }}
        >
          <Toolbar />
          <Box sx={{ mt: 4, mb: 4, paddingLeft: 2, paddingRight: 2 }}>
            <Box>
              <Orders />
            </Box>
          </Box>
        </Box>
      </Box>
      {loading && <LoadingSpinner />}
    </ThemeProvider>
  );
}
