import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getCheckoutTheme from "./getCheckoutTheme";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PurplegradientIcon from "./Purplegradient/PurplegradientIcon";
import axios from "axios";
import Cookies from "js-cookie";
import { useSnackbar } from './Snackbar';
import LoadingSpinner from './LoadingSpinner';

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));



const LoginForm = () => {
  const checkoutTheme = createTheme(getCheckoutTheme("light"));
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState(true);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [emailBorderColor, setEmailBorderColor] = useState('');

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  
    if (emailRegex.test(email)) {
      setEmailError(false);
      setEmailBorderColor(''); // Reset border color if email is valid
      return true;
    } else {
      setEmailError(true);
      setEmailBorderColor('red'); // Set border color to red if email is invalid
      return false;
    }
  };

  const getFieldProps = (fieldName) => {
    // console.log('fieldName==>>',fieldName)
    const isEmailField = fieldName;
    const value = formData.email
    
  
    return {
      name: fieldName,
      onChange: handleChange,
      value: value,
      style: {
        borderColor: (isEmailField && emailBorderColor),
      }
    };
  };

  const handleNumericInputChange = (event) => {
    const value = event.target.value.replace(/\D/, "").slice(0, 10); // Remove non-numeric characters
    setMobileNumber(value);
    if (value.length === 10) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENVIRONMENT}/api/auth/admin/login`,
        {
          email: formData.email,
          password: formData.password,
        }
      );
      const { token, data } = response.data;
      Cookies.set("token", token);
      Cookies.set("userData", JSON.stringify(data));
      showSnackbar(response.data.message, 'success');
      navigate("/home");
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
    setLoading(false)
  };
  

  return (
    <ThemeProvider theme={checkoutTheme}>
      <div
        style={{
          width: "100%",
          height: "100vh",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          overflow: "hidden",
        }}
      >
        <PurplegradientIcon style={{ width: "100%", minHeight: "100vh" }} />
      </div>
      <Container
        maxWidth="xs"
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 3rem 1rem 4rem",
            boxShadow: "4px 2px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "1rem",
            background: "linear-gradient(to right, #FFFFFF, #d1e8ff)",
            width: "100%",
          }}
        >
          <Typography
            variant="heading"
            // component="h4"
            gutterBottom
            sx={{ textAlign: "left", marginBottom: "2em" }}
          >
            Admin Portal
          </Typography>
          <Typography
            variant="subheading"
            // component="h2"
            // gutterBottom
            sx={{ textAlign: "left" }}
          >
            Log In
          </Typography>
          {/* <Typography variant="text" gutterBottom sx={{ textAlign: "left" }}>
            Please enter your email and password.
          </Typography> */}
          <Grid container spacing={3} marginTop={2}>
            <FormGrid item xs={12}>
              <FormLabel htmlFor="email" sx={{ textAlign: "left" }} required >
                <Typography variant="label" sx={{ fontFamily: 'Inter'}}>
                  Email
                </Typography>
              </FormLabel>
              <OutlinedInput
  {...getFieldProps('email')}
  id="email"
  name="email"
  type="email"
  placeholder="Enter your email address"
  onChange={(e) => {
    handleChange(e);
    validateEmail(e.target.value);
  }}
  endAdornment={
    <InputAdornment position="end">
      {emailError === false && formData.email && ( // Only show if email is valid and not empty
        <CheckCircleOutlineIcon sx={{ color: "green" }} />
      )}
    </InputAdornment>
  }
/>
            </FormGrid>
            <FormGrid item xs={12}>
              <FormLabel htmlFor="email" sx={{ textAlign: "left" }} required >
                <Typography variant="label" sx={{ fontFamily: 'Inter'}}>
                  Password
                </Typography>
              </FormLabel>
              <OutlinedInput
  id="password"
  name="password"
  type="password" // Changed to password type for security
  placeholder="Enter your password"
  required
  value={formData.password}
  onChange={handleChange}
  error={error && mobileNumber.length !== 10}
  endAdornment={
    <InputAdornment position="end">
      {formData.password.length > 8 && ( // Only show if password is more than 8 characters
        <CheckCircleOutlineIcon sx={{ color: "green" }} />
      )}
    </InputAdornment>
  }
/>
            </FormGrid>
            <Grid item xs={12} sx={{ marginBottom: 4}}>
            <Button
  variant="contained"
  fullWidth
  onClick={(e)=>handleLogin(e)}
  style={{ borderRadius: "25vw", marginTop: 25 }}
  disabled={emailError ||  formData.password.length <= 8}
  size="medium"
>
  <Typography variant="buttonText" >Log In</Typography>
</Button>
            </Grid>
          </Grid>
          {/* <Typography
            variant="text"
            gutterBottom
            sx={{ textAlign: "center", marginTop: "3em" }}
          >
            Don't have an account?{" "}
            <Link
              to="/signup"
              style={{ color: "#43349C", textDecoration: "none" }}
            >
              <Typography variant="link">
              Register here.
              </Typography>
            </Link>
          </Typography> */}
        </Box>
      </Container>
      {loading && <LoadingSpinner />}
    </ThemeProvider>
  );
};

export default LoginForm;
