import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";

export const MainListItems = ({ activePage, page }) => {
  const [selectedItem, setSelectedItem] = useState(activePage);
  const navigate = useNavigate();

  const handleItemClick = (item) => {
    if (item === "people") {
      setSelectedItem(item);
      navigate("/home");
    }
  };

  return (
    <List component="nav">
      {/* <ListItemButton
  selected={selectedItem === 'home'}
  onClick={() => handleItemClick('home')}
  style={{
    paddingLeft: '10px',
    background: '#ffffff',
    marginBottom: '20px'
  }}
>
  <ListItemIcon
    style={{
      backgroundColor: selectedItem === 'home' ? '#d0c9eb' : 'transparent',
      minWidth: '10px',
      padding: selectedItem === 'home' ? '12px' : '0px',
      borderRadius: '10px',
      paddingLeft: '12px'
    }}
  >
    <HomeIcon sx={{ color: selectedItem === 'home' ? '#342b86' : 'inherit' }} />
  </ListItemIcon>
</ListItemButton> */}

      {/* {selectedItem === 'people' && ( */}
      <ListItemButton
        selected={selectedItem === "people"}
        onClick={() => handleItemClick(selectedItem)}
        sx={{
          paddingLeft: "10px",
          background: "#ffffff",
          "&:hover": {
            backgroundColor: selectedItem === "people" ? "#f0f0f0" : "#ffffff", // Change background color on hover
          },
        }}
      >
        <ListItemIcon
          style={{
            backgroundColor:
              selectedItem === "people" && page !== "manage-admins"
                ? "#d0c9eb"
                : "transparent",
            minWidth: "10px",
            padding: selectedItem === "people" ? "12px" : "0px",
            borderRadius: "10px",
            paddingLeft: "12px",
          }}
        >
          <PeopleIcon
            sx={{
              color:
                selectedItem === "people" && page !== "manage-admins"
                  ? "#342b86"
                  : "inherit",
            }}
          />
        </ListItemIcon>
        {/* <ListItemText primary="People" /> */}
      </ListItemButton>
      {/* )} */}
      {/* Add other menu items as needed */}
    </List>
  );
};

export const secondaryListItems = ({ activePage }) => {
  return (
    <List component="nav">
      {activePage === "home" && (
        <React.Fragment>
          <ListItemButton>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Current month" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Last quarter" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Year-end sale" />
          </ListItemButton>
        </React.Fragment>
      )}
      {/* Add more conditional secondary items as needed */}
    </List>
  );
};
