// AppBarComponent.js
import React, { useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import {
    styled,
    useTheme
  } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { MainListItems, secondaryListItems } from './ListItems';
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from "@mui/material/MenuItem";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { Link, useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Cookies from "js-cookie";
import axios from "axios";
import { useSnackbar } from './Snackbar';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const settings = ["Manage Users", "Logout"];

const drawerWidth = 72;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#ffffff",
    color: "black",
    boxShadow: "none",
    ...(open && {
      marginLeft: isMobile ? drawerWidth : drawerWidth,
      width: isMobile
        ? `calc(100% - ${drawerWidth}px)`
        : `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    ...(!open && {
      marginLeft: isMobile ? 0 : drawerWidth,
      width: isMobile ? "100%" : `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
  };
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return {
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: open ? drawerWidth : isMobile ? 0 : theme.spacing(7),
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: isMobile ? 0 : theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: isMobile ? 0 : theme.spacing(9),
        },
      }),
    },
  };
});

function ResponsiveAppBar({mainPage,currentPage, pageUrl, userId}) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [open, setOpen] = React.useState(false);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const userData = Cookies.get("userData")
    ? JSON.parse(Cookies.get("userData"))
    : navigate("/");

    const handleMenuItemClick = (setting) => {
      if (setting === "Manage Users") {
        navigate("/manage-users");
      } else if (setting === "Logout") {
        Cookies.remove("token");
        Cookies.remove("userData");
        navigate("/");
      }
    };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOnUrlClick = (userId)=>{
    if(userId && userId!== undefined){
      navigate("/patient-profile", {
        state: {
          patient_id: userId
        },
      });
    }else if(pageUrl){
      navigate(pageUrl)
    }else{
      navigate("/home")
    }
  }

  const token = Cookies.get('token') ? Cookies.get('token') : navigate("/");

  const getDocuments = async (searchQuery) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ENVIRONMENT}/api/users`, {
        params: {
          search: searchQuery,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPatients(response.data.data);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
        // showSnackbar(error.response.data.message, 'error');
      }
    } 
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // Handle search functionality here
    // console.log("Searching for:", searchQuery);
  };
  const getOptionLabel = (option) => {
    if (!option) return '';

    const { first_name, last_name } = option;
    if (first_name && last_name) {
      return `${first_name} ${last_name}`;
    } else {
      return '';
    }
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSelectedOption(null);
      setSearchQuery(event.target.value);
    }
  };

  const handleOptionSelect = (val) => {
    if(val!== null && val._id!== undefined && val._id!== 'undefined' && val._id!== null){
          navigate("/patient-profile", {
      state: {
        userId: val._id
      },
    });
    }
  };

  return (
    <>
    <AppBar position="absolute" open={open}>
    <Toolbar
      sx={{
        pr: "24px",
      }}
    >
      <Breadcrumbs separator={'>'} aria-label="breadcrumb">
        {/* <Link underline="none" to={pageUrl ? pageUrl : ''} sx={{ color: '', textDecoration: 'none' }}> */}
          <Typography onClick={()=>handleOnUrlClick(userId)}  sx={{
            color: '',
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'inline-block',
          }}
          variant="hvariant">
            {mainPage? mainPage : ''}
          </Typography>
        {/* </Link> */}
        <Typography color="text.primary" variant="h5">{currentPage ? currentPage : ''}</Typography>
      </Breadcrumbs>
      <Typography
        variant="h5"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1, fontFamily: "Inter", fontWeight: 600 }}
      >
        {/* Patients */}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {/* <Autocomplete
          freeSolo
          options={patients.map((option) => {
            if (option.first_name !== undefined) {
              return option;
            }
          })}
          getOptionLabel={getOptionLabel}
          filterOptions={(options, { inputValue }) => {
            return options.filter((option) => {
              const filterData =
                option.first_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                option.email
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                option.phone
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              return filterData
                ? filterData
                : { first_name: "no patient" };
            });
          }}
          onInputChange={handleSearchInputChange}
          sx={{
            width: isMobile ? "12rem" : "20rem",
            mr: isMobile ? 1 : 5,
          }}
          onChange={(event, value) => {
            setSelectedOption(value);
            handleOptionSelect(value, event);
          }}
          renderInput={(params) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "1px 16px",
                marginRight: 2,
              }}
            >
              <TextField
                {...params}
                placeholder="Search Patients"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  sx: {
                    "&::placeholder": {
                      color: "#7B8291",
                      fontFamily: "Inter",
                    },
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "#7B8291", mr: 1 }} />
                    </InputAdornment>
                  ),
                  onKeyDown: handleKeyDown, // Handle key down event
                }}
              />
            </Box>
          )}
        /> */}

        <Typography
          variant="body1"
          sx={{
            mr: 2,
            display: isMobile ? "none" : "block",
            fontFamily: "Inter",
            cursor: "pointer",
          }}
          onClick={handleOpenUserMenu}
        >
          <Tooltip title="Open settings">
            {userData
              ? userData.first_name + " " + userData.last_name
              : ""}
          </Tooltip>
        </Typography>
        <Typography variant="customFont2">
          <Tooltip title="Open settings">
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0, color: "#5B43BD" }}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      </Box>

      <Menu
        sx={{
          mt: "45px",
          "& .MuiPaper-root": {
            backgroundColor: "#ffffff",
            minWidth: "10em",
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) =>
          // Check if the setting is 'Manage user setting' and user role is 'superadmin'
          setting === "Manage Users" &&
          userData && userData.role !== "superadmin" ? null : (
            <MenuItem
              key={setting}
              onClick={() => handleMenuItemClick(setting)}
            >
              <Typography textAlign="center" sx={{ fontFamily: "Inter" }}>
                {setting}
              </Typography>
            </MenuItem>
          )
        )}
      </Menu>
    </Toolbar>
  </AppBar>
          <Drawer variant="permanent" open={open}>
          {/* <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar> */}
           <List component="nav" sx={{ mt: 7 }}>
          <MainListItems activePage="people" />
        {secondaryListItems}
          </List>
        </Drawer>
        </>
  );
}

export default ResponsiveAppBar;
