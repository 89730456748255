import React, { createContext, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const handleClose = () => {
    setOpen(false);
  };

  const showSnackbar = (msg, severity = 'info') => {
    setOpen(true);
    setMessage(msg);
    setSeverity(severity);
  };

  const severityColors = {
    success: '#B6D98A', // Green color for success
    error: '#FF8787',   // Red color for error
    warning: '#ff9800', // Orange color for warning
    info: '#2196f3',    // Blue color for info (default)
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
  open={open}
  autoHideDuration={2000}
  onClose={handleClose}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
>
  <MuiAlert
    onClose={handleClose}
    severity={severity}
    sx={{
      backgroundColor: severityColors[severity] || severityColors.info,
      color: '#fff', // Text color
    }}
  >
    {message}
  </MuiAlert>
</Snackbar>

    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
