import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // TextField,
  // TextArea,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Grid,
  OutlinedInput,
  Link,
  Card,
  CardContent,
  Snackbar,
} from "@mui/material";
import {
  MoreVert,
  PictureAsPdf,
  Description,
  Person,
  Close,
  CloudUpload,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import InputAdornment from "@mui/material/InputAdornment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PateintDocumentViewer from "./PateintDocumentViewer";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import axios from "axios";
import Cookies from "js-cookie";
// import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "./Snackbar";
import LoadingSpinner from "./LoadingSpinner";
import ConfirmationDialog from './ConfirmationDialog';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { saveAs } from 'file-saver';


const DocumentList = ({ token, patient }) => {
  const [tabValue, setTabValue] = React.useState("uploaded");
  const [anchorEl, setAnchorEl] = useState({});
  const [openUploadDialog, setOpenUploadDialog] = React.useState(false);
  const [documentType, setDocumentType] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedDocs, setuploadedDocs] = useState([]);
  const [viewingDoc, setViewingDoc] = useState(null); // State for the document being viewed
  const [documentUrl, setDocumentUrl] = useState("");
  const [isdownload, setIsdownload] = useState(false);
  const [filetoDownload, setFileToDownload] = useState(null);
  const [menuIndex, setMenuIndex] = useState(null);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentData, setDocumentData] = useState(null);

  const { showSnackbar } = useSnackbar();

  const [isChecked, setIsChecked] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [actionToConfirm, setActionToConfirm] = useState({ actionType: null, docId: null, actionText:null });

  const handleOpenConfirmation = (actionType, docId,actionText) => {
    setActionToConfirm({ actionType, docId, actionText });
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setActionToConfirm({ actionType: null, docId: null,actionText: null });
    handleMenuClose();
  };

  const handleConfirmAction = () => {
    const { actionType, docId, actionText } = actionToConfirm;
    if (actionType && docId) {
      switch (actionType) {
        case 'Release':
          releaseDocument(docId);
          break;
        case 'Revoke':
          revokeDocument(docId);
          break;
        case 'Archive':
          updateDocumentArchiveStatus(docId, "archive");
          break;
        case 'Restore':
          updateDocumentArchiveStatus(docId, "unarchive");
          break;
        default:
          break;
      }
    }
    setOpenConfirmation(false);
    setActionToConfirm({ actionType: null, docId: null, actionText: null });
  };




  const navigate = useNavigate();
  const getDocuments = async (isChecked = false) => {
    // console.log('api is runing', isChecked)
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENVIRONMENT}/api/document`,
        {
          params: {
            user_id: patient._id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.data !== undefined) {
        if (isChecked) {
          setuploadedDocs(response.data.data);
        } else {
          const filteredData = response.data.data.filter(
            (item) => !item.has_archive
          );
          setuploadedDocs(filteredData);
        }
      }else{
        setuploadedDocs([]);
      }
      // alert(response.data.message)
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, "error");
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, "error");
        }
      } else {
        showSnackbar(error.message, "error");
        // showSnackbar(error.response.data.message, 'error');
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (patient.length !== 0) {
      // console.log('token===>>', token);
      getDocuments();
    }
  }, [patient, token]);

  const handleCheckboxChange = (event) => {
    // console.log('----------->>',event.target.value, event.target.checked)
    setIsChecked(event.target.checked);
    getDocuments(event.target.checked);
    // alert(event.target.checked)
    // // console.log('patient==>>',patient)
    // getDocuments(event.target.checked);
  };

  const deleteDocuments = async (docId) => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_ENVIRONMENT}/api/document/${docId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getDocuments();
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, "error");
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, "error");
        }
      } else {
        showSnackbar(error.message, "error");
        // showSnackbar(error.response.data.message, 'error');
      }
    }
    setLoading(false);
  };

  const updateDocumentArchiveStatus = async (docId, action) => {
    // action: 'archive' or 'unarchive'
    // console.log('token====>>', token);
    setLoading(true);
    handleMenuClose();
    let url = action === "archive" ? "archive" : "restore";
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENVIRONMENT}/api/document/${url}/${docId}`,
        { action }, // Send the action in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let note = action === "archive" ? "archived" : "restored";
      let variant = action === "archive" ? "success" : "success";
      showSnackbar(`Document ${note} successfully.`, variant);
      if (action === "archive") {
        if (isChecked) {
          getDocuments(true);
        } else {
          getDocuments(false);
        }
      } else {
        getDocuments(true);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, "error");
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, "error");
        }
      } else {
        showSnackbar(error.message, "error");
        // showSnackbar(error.response.data.message, 'error');
      }
    }
    setLoading(false);
  };

  const releaseDocument = async (docId) => {
    // action: 'archive' or 'unarchive'
    // console.log('token====>>', token);
    setLoading(true);
    handleMenuClose();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENVIRONMENT}/api/document/release/${docId}`,
        {}, // Empty object as request body if not sending any data
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      showSnackbar(`Document released to patient.`, "success");
      if (isChecked) {
        getDocuments(true);
      } else {
        getDocuments(false);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, "error");
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, "error");
        }
      } else {
        showSnackbar(error.message, "error");
        // showSnackbar(error.response.data.message, 'error');
      }
    }
    setLoading(false);
  };

  const revokeDocument = async (docId) => {
    handleMenuClose();
    setLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENVIRONMENT}/api/document/revoke/${docId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      showSnackbar(`Document access to the patient revoked.`, "success");
      if (isChecked) {
        getDocuments(true);
      } else {
        getDocuments(false);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, "error");
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, "error");
        }
      } else {
        showSnackbar(error.message, "error");
        // showSnackbar(error.response.data.message, 'error');
      }
    }
    setLoading(false);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleUploadClick = () => {
    setOpenUploadDialog(true);
    setSelectedFiles([]);
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setDocumentType("");
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files); // Convert FileList to an array
    // Check for duplicates
    const uniqueNewFiles = newFiles.filter(
      (file) =>
        !selectedFiles.some((selectedFile) => selectedFile.name === file.name)
    );
    setSelectedFiles((prevFiles) => [...prevFiles, ...uniqueNewFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files); // Get dropped files

    // Check for duplicates
    const uniqueNewFiles = newFiles.filter(
      (file) =>
        !selectedFiles.some((selectedFile) => selectedFile.name === file.name)
    );

    // Update the selectedFiles state
    setSelectedFiles((prevFiles) => [...prevFiles, ...uniqueNewFiles]);
  };

  useEffect(() => {}, [selectedFiles]);

  const uploadFormData = {
    doc_type: documentType ? documentType : "other",
    description: description,
    docs: selectedFiles,
  };

  const handleUpload = async () => {
    setUploading(true);
    setLoading(true);
    const formData = new FormData();
    formData.append("doc_type", documentType ? documentType : "other");
    formData.append("description", description);
    selectedFiles.forEach((file) => {
      formData.append("docs", file);
    });
    // console.log('formData===>>',formData, documentType, selectedFiles, description)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENVIRONMENT}/api/document/${patient._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSnackbar("Documents uploaded successfully.", "success");
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, "error");
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, "error");
        }
      } else {
        showSnackbar(error.message, "error");
        // showSnackbar(error.response.data.message, 'error');
      }
    }
    setUploading(false);
    setOpenUploadDialog(false);
    getDocuments();
    setLoading(false);
  };

  const handleDocumentUrlSet = (url) => {
    setDocumentUrl(url);
  };

  const getNotes = async (e) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENVIRONMENT}/api/note/${patient._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNotes(response.data.data);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, "error");
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, "error");
        }
      } else {
        showSnackbar(error.message, "error");
        // showSnackbar(error.response.data.message, 'error');
      }
    }
    setLoading(false);
  };

  const handleNotesSubmit = async (e) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENVIRONMENT}/api/note/${patient._id}`,
        { note: note },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNote("");
      showSnackbar(`Note Added.`, "success");
      getNotes();
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, "error");
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, "error");
        }
      } else {
        showSnackbar(error.message, "error");
        // showSnackbar(error.response.data.message, 'error');
      }
    }
    setLoading(false);
  };

  const CurrentDate = () => {
    const currentDate = new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return currentDate.toLocaleDateString(undefined, options);
  };

  const documentsSharedWithYou = [];

  const getIcon = (type) => {
    const docType = type.split(".")[type.split(".").length - 1].toLowerCase();
    switch (docType) {
      case "pdf":
        return <PictureAsPdf color="error" />;
      case "png":
      case "jpg":
      case "jpeg":
      case "img":
        return <ImageOutlinedIcon color="primary" />;
      default:
        return null;
    }
  };

  const getCorrectFileName = (path) => {
    const parts = path.split("/");
    return parts[parts.length - 1];
  };

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const options = { month: "long", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleRemoveFile = (indexToRemove) => {
    setSelectedFiles(
      selectedFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const fetchDocumentForViewing = async (url) => {
    setLoading(true);
    setDocumentUrl("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENVIRONMENT}/api/document/view-document`, 
        {
          params: { s3Url: url },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDocumentUrl(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
      }
    }
  };

  const initiateDownload = async (url) => {
    handleMenuClose();
    setLoading(true);
  
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENVIRONMENT}/api/document/download-document`,
        {
          params: { s3Url: url },
          responseType: 'blob', // Ensure responseType is set to 'blob'
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  

      saveAs(response.data, url.split('/').pop());
  
      setIsdownload(false);
      setFileToDownload(null);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
          showSnackbar(error.response.data.message, 'error');
          navigate("/");
        } else {
          showSnackbar(error.response.data.message, 'error');
        }
      } else {
        showSnackbar(error.message, 'error');
      }
    }
  };


  const handleViewDocument = (doc) => {
    setViewingDoc(doc);
    setIsdownload(false); // Ensure download mode is off
    fetchDocumentForViewing(doc.path);
  };

  const handleCloseViewer = () => {
    setViewingDoc(null);
    setIsdownload(false);
  };

  const handleDownload = () => {
    setIsdownload(true);
    initiateDownload(viewingDoc.path);
    setViewingDoc(null);
  };


  const handleDocDownloadMenu = (doc) => {
    setFileToDownload(doc);
    setIsdownload(true);
    initiateDownload(doc.path);
  };

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };

  const formatNotesDate = (dateString) =>
    new Date(dateString).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  const formatTime = (dateString) =>
    new Date(dateString).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

  const renderDocumentList = (documents) => (
    <List
      sx={{
        pt: 0,
      }}
    >
      {documents && documents.length > 0 ? (
        documents.map((doc, index) => (
          <Paper
            key={doc._id}
            variant="document"
            sx={{
              marginBottom: 2,
              backgroundColor: "white",
              boxShadow: "none",
              border: "1px solid #e0e0e0",
            }}
          >
            <ListItem
              sx={{
                ml: "3%",
              }}
            >
              <ListItemIcon sx={{ minWidth: 37 }}>
                {getIcon(doc.original_name)}
              </ListItemIcon>
              <ListItemText
                onClick={() => handleViewDocument(doc)}
                sx={{ cursor: "pointer" }}
                primary={
                  <Typography
                    variant="body4"
                    sx={{
                      fontFamily: "Inter",
                      color: doc.has_archive === true ? "red" : "",
                    }}
                  >
                    {getCorrectFileName(doc.path)}
                  </Typography>
                }
                secondary={
                  <>
                    <Box sx={{ width: "99%" }}>
                      <Typography
                        variant="body8"
                        // color="text.secondary"
                        sx={{
                          fontStyle: "italic",
                          fontWeight: "bold",
                          fontFamily: "Inter",
                        }}
                      >
                        {doc.description}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ mt: 0.7 }}>
                      <Box sx={{ minWidth: "30%", display: "flex" }}>
                        <Person
                          sx={{
                            marginRight: 0.5,
                            color: "#7B8291",
                            fontSize: 16,
                          }}
                        />
                        <Typography
                          variant="body8"
                          sx={{
                            marginRight: 1,
                            fontWeight: "bold",
                            fontFamily: "Inter",
                          }}
                        >
                          {`${doc.uploaded_by.first_name} ${doc.uploaded_by.last_name}`}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", mr: 30 }}>
                        <AccessTimeIcon
                          sx={{
                            marginRight: 0.5,
                            color: "#7B8291",
                            fontSize: 16,
                          }}
                        />
                        <Typography variant="body8" sx={{ fontWeight: "bold" }}>
                          Added {formatDate(doc.createdAt)}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {doc && doc.has_release === true && (
                    <Box
                      sx={{
                        backgroundColor: "#B6D98A",
                        color: "#000",
                        borderRadius: "12px",
                        padding: "2px 8px",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "14px", fontFamily: "Inter" }}
                      >
                        Released
                      </Typography>
                    </Box>
                  )}

                  {doc && doc.has_archive === true && (
                    <Box
                      sx={{
                        backgroundColor: "#FF8787",
                        color: "#000",
                        borderRadius: "12px",
                        padding: "2px 8px",
                        display: "inline-flex",
                        alignItems: "center",
                        ml: 1,
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "14px", fontFamily: "Inter" }}
                      >
                        Archived
                      </Typography>
                    </Box>
                  )}
                  <IconButton
                    edge="end"
                    onClick={(event) => handleMenuClick(event, index)}
                  >
                    <MoreVert />
                  </IconButton>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && menuIndex === index}
                  onClose={handleMenuClose}
                  sx={{
                    mt: 0,
                    fontFamily: "Inter",
                    "& .MuiPaper-root": {
                      backgroundColor: "#ffffff",
                      minWidth: "10em",
                    },
                  }}
                >
                  <MenuItem onClick={() => handleDocDownloadMenu(doc)}>
                    Download
                  </MenuItem>
                  {doc &&
                    doc.has_release === false &&
                    doc.has_archive === false &&
                    doc.uploaded_by.role !== "patient" && (
                      <MenuItem onClick={() => handleOpenConfirmation('Release', doc._id, 'Are you sure you want to release this document to the patient?')}>
                        Release to Patient
                      </MenuItem>
                    )}
                  {doc &&
                    doc.has_release === true &&
                    doc.uploaded_by.role !== "patient" && (
                      <MenuItem onClick={() => handleOpenConfirmation('Revoke', doc._id,'Are you sure you want to revoke patient access to this document?')}>
                        Revoke
                      </MenuItem>
                    )}
                  {doc.has_archive ? (
                    <MenuItem
                      onClick={() =>
                        handleOpenConfirmation('Restore', doc._id, 'Are you sure you want to restore this document?')
                      }
                    >
                      Restore
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() =>
                        handleOpenConfirmation('Archive', doc._id, 'Are you sure you want to archive this document?')
                      }
                      sx={{ color: "red" }}
                    >
                      Archive
                    </MenuItem>
                  )}
                </Menu>
              </ListItemSecondaryAction>
            </ListItem>
          </Paper>
        ))
      ) : (
        <Typography variant="body1" sx={{ mt: 3, fontFamily: "Inter" }}>
          You have not uploaded any documents yet.
        </Typography>
      )}
    </List>
  );

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          textColor="inherit"
          TabIndicatorProps={{
            style: { backgroundColor: "#7B26C9", height: 5 },
          }}
        >
          <Tab
            value="uploaded"
            label="Documents"
            textColor="inherit"
            sx={{
              textTransform: "none",
              color: tabValue === "uploaded" ? "#7B26C9" : "#525968",
              fontWeight: tabValue === "uploaded" ? 800 : 400,
              fontSize: 14,
              fontFamily: "Inter",
              "&:hover": {
                color: "#7B26C9", // Text color on hover
                opacity: 0.7, // Reduce opacity on hover
                borderBottom: "2px solid #7B26C9", // Bottom border on hover
                transition: "all 0.3s ease", // Smooth transition
              },
            }}
          />
          <Tab
            value="shared"
            label="Notes"
            textColor="inherit"
            sx={{
              textTransform: "none",
              color: tabValue === "shared" ? "#7B26C9" : "#525968",
              fontWeight: tabValue === "shared" ? 800 : 400,
              fontSize: 14,
              fontFamily: "Inter",
              "&:hover": {
                color: "#7B26C9", // Text color on hover
                opacity: 0.7, // Reduce opacity on hover
                borderBottom: "2px solid #7B26C9", // Bottom border on hover
                transition: "all 0.3s ease", // Smooth transition
              },
            }}
            onClick={getNotes}
          />
        </Tabs>
      </Box>

      <hr
        style={{
          borderTop: "1px solid #CCCCCC",
          width: "100%",
          marginTop: 0,
        }}
      />
      <Box
        sx={{
          marginTop: 2,
          ml: tabValue === "shared" ? 0 : 13,
          mr: tabValue === "shared" ? 0 : 13,
        }}
      >
        {tabValue === "uploaded" && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                // startIcon={<BackupOutlinedIcon />}
                sx={{ marginBottom: 5, mt: 4 }}
                size="large"
                onClick={handleUploadClick}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: "Inter",
                    fontSize: 16,
                  }}
                >
                  Upload Document
                </Typography>
              </Button>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  // defaultChecked
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  color="primary"
                  inputProps={{ "aria-label": "show archive" }}
                  sx={{ "&.Mui-checked": { color: "#7B26C9" } }}
                />
                <Typography variant="body2">Show Archived Documents</Typography>
              </div>
            </div>

            {uploadedDocs && uploadedDocs.length > 0 && (
              <>
                {/* <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Inter'}}>
            Patient Documents
          </Typography> */}
                <Typography
                  variant="h6"
                  sx={{
                    mt: uploadedDocs && uploadedDocs.length ? 4 : 0,
                    fontFamily: "Inter",
                  }}
                >
                  {CurrentDate()}
                </Typography>
              </>
            )}

            {renderDocumentList(uploadedDocs)}
          </>
        )}
        {tabValue === "shared" && (
          <div style={{ ml: 0, mr: 0 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <OutlinedInput
                id="notes"
                name="notes"
                type="text"
                placeholder="Write a note..."
                multiline
                rows={4}
                fullWidth
                startAdornment={
                  <InputAdornment position="start" sx={{ marginRight: "8px" }}>
                    <EditOutlinedIcon style={{ color: "#A0ABB9" }} />
                  </InputAdornment>
                }
                sx={{
                  width: "100%",
                  minHeight: 100,
                  "& input": {
                    paddingLeft: "40px", // Adjusted padding to accommodate the icon
                    fontStyle: "italic",
                    fontWeight: 300,
                    fontSize: 14,
                    fontFamily: "Inter",
                    color: "#A0ABB9",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "grey",
                    borderWidth: "2px", // Adjust the thickness of the border
                  },
                  display: "flex",
                  alignItems: "baseline", // Align icon and input baseline
                }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <Box
                sx={{
                  height: 48,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  mt: 2,
                }}
              >
                {note && (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="medium"
                      sx={{ width: "auto", mr: 1 }}
                      onClick={() => setNote("")}
                    >
                      <Typography
                        variant="caption"
                        sx={{ fontFamily: "Inter" }}
                      >
                        Cancel
                      </Typography>
                    </Button>
                    <Button
                      onClick={handleNotesSubmit}
                      variant="contained"
                      color="primary"
                      size="medium"
                      sx={{ width: "auto" }}
                    >
                      <Typography
                        variant="caption"
                        sx={{ fontFamily: "Inter" }}
                      >
                        Submit
                      </Typography>
                    </Button>
                  </>
                )}
              </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
              {/* <Box sx={{ display: 'inline-block' }}>
    <Typography color="#5B43BD" gutterBottom sx={{ fontFamily: 'Inter', fontWeight: 800 }}>
      Provider Notes
    </Typography>
    <Box
      sx={{
        mt: 1,  // Adjust the margin top to your preference
        height: '4px',  // Thickness of the line
        backgroundColor: '#5B43BD',  // Color of the line
        width: '100%'
      }}
    />
  </Box> */}
            </Box>

            {notes && notes.length > 0 ? (
              notes.map((note, index) => (
                <Card
                  key={index}
                  variant="outlined"
                  sx={{ borderRadius: "4px", mb: 2 }}
                >
                  {" "}
                  {/* Adjust border radius here */}
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box sx={{ flexShrink: 0, mr: 2 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#0099D1",
                            fontWeight: 600,
                            fontFamily: "Inter",
                          }}
                        >
                          {note._addedby_user[0].email}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 600, fontFamily: "Inter" }}
                          >
                            {formatNotesDate(note.createdAt)}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 600, fontFamily: "Inter" }}
                          >
                            {formatTime(note.createdAt)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{ flexGrow: 1, fontFamily: "Inter", width: "100%" }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "Inter" }}
                        >
                          {note.note}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))
            ) : (
              <Typography>No notes available</Typography>
            )}
          </div>
        )}
      </Box>
      <div style={{ background: "#ffffff" }}>
        <Dialog
          open={openUploadDialog}
          onClose={handleCloseUploadDialog}
          PaperProps={{
            sx: { backgroundColor: "#ffffff", maxWidth: "23.10em" },
          }}
        >
          <DialogTitle>
            <Typography variant="modalHeading" sx={{ fontFamily: "Inter" }}>
              Upload Document
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseUploadDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel required>
                    <Typography variant="label2" sx={{ fontFamily: "Inter" }}>
                      Document Type
                    </Typography>
                  </FormLabel>
                  <Select
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                    input={<OutlinedInput label="Document Type" />}
                    displayEmpty
                    MenuProps={{
                      sx: {
                        fontFamily: "Inter",
                        "& .MuiPaper-root": {
                          backgroundColor: "#ffffff",
                        },
                        "& .MuiSelect-select": {
                          color: "grey",
                          backgroundColor: "#ffffff",
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="ID Proof" selected>
                      ID Proof
                    </MenuItem>
                    <MenuItem value="Insurance">Insurance</MenuItem>
                    <MenuItem value="Test Report">Test Report</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <FormLabel
                    htmlFor="description"
                    // sx={{
                    //   textAlign: "left",
                    //   fontSize: 12,
                    //   fontWeight: "medium",
                    // }}
                  >
                    <Typography variant="label2" sx={{ fontFamily: "Inter" }}>
                      Description
                    </Typography>
                  </FormLabel>
                  <OutlinedInput
                    id="description"
                    name="description"
                    type="text"
                    placeholder="Enter a description"
                    multiline
                    rows={4} // Set the number of rows to define the height
                    sx={{ width: "100%", minHeight: 100 }} // Custom styles for width and minimum height
                    // onChange={handleChange}
                    inputProps={{
                      style: {
                        paddingLeft: "0",
                        // fontStyle: "italic",
                        fontWeight: 300,
                        marginRight: 50,
                        fontSize: 14,
                        fontFamily: "Inter",
                        color: "#525968",
                      },
                    }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: "2px dashed #BAC3CE",
                    padding: 2,
                    textAlign: "center",
                    marginTop: 2,
                    cursor: "pointer",
                  }}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    accept=".pdf,.jpeg,.jpg,.png"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-upload"
                    multiple="multiple"
                  />
                  <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
                    {/* {selectedFile ? (
                    selectedFile.name
                  ) : ( */}
                    <>
                      <Typography variant="body1">
                        <BackupOutlinedIcon sx={{ fontSize: 30 }} />
                      </Typography>
                      <Typography variant="body6" sx={{ fontFamily: "Inter" }}>
                        Drop file here, or{" "}
                        <Link
                          style={{
                            color: "#43349C",
                            textDecoration: "none",
                            fontFamily: "Inter",
                          }}
                        >
                          click to upload
                        </Link>
                      </Typography>
                      <Box sx={{ ml: 7, mr: 7 }}>
                        <Typography
                          variant="body5"
                          sx={{ fontWeight: 600, fontFamily: "Inter" }}
                        >
                          (PDF, JPEG, JPG, or PNG. Max file size 10 MB)
                        </Typography>
                      </Box>
                    </>
                    {/* )} */}
                  </label>
                </Box>
              </Grid>
              {selectedFiles &&
                selectedFiles.map((file, index) => (
                  <Grid item xs={12} key={index}>
                    <Box
                      sx={{
                        // border: "1px solid grey",
                        // padding: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: 1,
                        bgcolor: "#EDEFF3",
                      }}
                    >
                      <Typography
                        variant="body7"
                        sx={{ textAlign: "left", ml: 2, fontFamily: "Inter" }}
                      >
                        {file.name}
                      </Typography>
                      <IconButton onClick={() => handleRemoveFile(index)}>
                        <Close sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseUploadDialog}
              variant="outlined"
              color="primary"
            >
              <Typography variant="caption" sx={{ fontFamily: "Inter" }}>
                Cancel
              </Typography>
            </Button>
            <Button
              onClick={handleUpload}
              variant="contained"
              color="primary"
              disabled={!documentType || !selectedFiles.length || uploading}
              startIcon={uploading ? <CircularProgress size={24} /> : null}
            >
              <Typography
                variant="buttonText"
                size="large"
                sx={{
                  fontFamily: "bold",
                  fontSize: 16,
                  fontFamily: "Inter",
                }}
              >
                {uploading ? "Uploading" : "Upload"}
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={viewingDoc !== null}
          onClose={handleCloseViewer}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ flexGrow: 1, fontFamily: "Inter" }}>
              {viewingDoc !== null
                ? getCorrectFileName(viewingDoc.path)
                : "Document Viewer"}
            </Typography>
            <div>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleDownload}
                aria-label="download"
                sx={{ mr: 1 }}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseViewer}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
            dividers
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
              padding: 0,
              overflow: "auto",
            }}
          >
            {documentUrl && (
              <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
                {viewingDoc?.path.endsWith(".pdf") ? (
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={documentUrl} />
                  </Worker>
                ) : (
                  <img
                    src={documentUrl}
                    alt="Document"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                )}
              </div>
            )}
          </DialogContent>
        </Dialog>

        {isdownload && (
          <div style={{ display: "none" }}>
            <iframe
              src={
                viewingDoc !== null
                  ? viewingDoc.path
                  : filetoDownload !== null
                  ? filetoDownload.path
                  : ""
              }
              width="100%"
              height="500px"
              title="Document"
            />
          </div>
        )}
      </div>
      {loading && <LoadingSpinner />}
      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmAction}
        actionType={actionToConfirm.actionType}
        docId={actionToConfirm.docId}
        actionText={actionToConfirm.actionText}
      />
    </Box>
  );
};

export default DocumentList;
