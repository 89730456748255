import React, { useEffect } from 'react';
import LoginForm from './components/SignIn'; 
import AdminHome from './components/AdminHome';
import CreatePatient from './components/CreatePatient';
import PateintProfile from './components/PateintProfile';
import EditPatientProfile from './components/EditPatientProfile';
import AdminRegistration from './components/AdminRegistration';
import ManageAdmins from './components/ManageAdmins';
import { SnackbarProvider } from './components/Snackbar';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

import './App.css';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location]);

  return null;
};

const App = () => {
  return (
    <Router>
      <SnackbarProvider>
        <RouteTracker />
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/home" element={<AdminHome />} />
          <Route path="/create-patient" element={<CreatePatient />} />
          <Route path="/patient-profile" element={<PateintProfile />} />
          <Route path="/edit-profile" element={<EditPatientProfile />} />
          <Route path="/create-admin" element={<AdminRegistration />} />
          <Route path="/manage-users" element={<ManageAdmins />} />
        </Routes>
      </SnackbarProvider>
    </Router>
  );
};

export default App;
